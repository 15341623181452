define('ksi/transforms/evals-list', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Transform.extend({
        serialize: function serialize(value) {
            return value.map(function (item) {
                return item['id'];
            });
        },
        deserialize: function deserialize(value) {
            return value.map(function (item) {
                return { 'id': item };
            });
        }
    });
});