define("ksi/helpers/module-type", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Helper.helper(function (type) {
        type = type[0];
        var ret = "blank";
        if (type === "correct") {
            ret = "correct";
        } else if (type === "incorrect") {
            ret = "incorrect";
        }
        return ret;
    });
});