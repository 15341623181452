define("ksi/models/basic-profile", ["exports", "ember-data", "ember", "ksi/config/environment"], function (exports, _emberData, _ember, _ksiConfigEnvironment) {

    /* This model contains only basic profile information.
     * It is loaded in session.
     * It contains only basic information, because getting basic information from
     * db in much faster than getting whole profile.
     */

    exports["default"] = _emberData["default"].Model.extend({
        // Relevant only if displaing user profile
        signed_in: _emberData["default"].attr("boolean", { "default": false }),

        first_name: _emberData["default"].attr("string"),
        last_name: _emberData["default"].attr("string"),
        nick_name: _emberData["default"].attr("string"),

        full_name: _ember["default"].computed("first_name", "nick_name", "last_name", function () {
            if (!this.get("nick_name") || this.get("nick_name").length === 0) {
                return this.get("first_name") + ' ' + this.get("last_name");
            }
            return this.get("first_name") + ' "' + this.get("nick_name") + '" ' + this.get("last_name");
        }),

        profile_picture: _emberData["default"].attr("string"),
        profile_picture_r: _ember["default"].computed("profile_picture", "gender", function () {
            var p = this.get("profile_picture");
            if (p) {
                return _ksiConfigEnvironment["default"].API_LOC + p;
            }
            if (this.get("gender") === "female") {
                return "/img/avatar-default-woman.svg";
            } else {
                return "/img/avatar-default.svg";
            }
        }),

        short_info: _emberData["default"].attr("string"),
        email: _emberData["default"].attr("string"),
        gender: _emberData["default"].attr("string"),

        role: _emberData["default"].attr("string", { defaultValue: "participant" }),
        admin: _ember["default"].computed("role", function () {
            return this.get("role") === "admin";
        }),

        organisator: _ember["default"].computed("role", function () {
            return this.get("role") === "org" || this.get("role") === "admin";
        }),

        // this property must be here, it defines the difference between user and profile
        // (orgs public "user" does not show his solved tasks, private "profile" does it)
        show_solved: true,

        role_str: _ember["default"].computed("gender", "role", function () {
            var ret = "";
            if (this.get("role") === "org") {
                ret = "organizátor";
            }
            if (this.get("role") === "admin") {
                ret = "administrátor";
            }
            if (this.get("role") === "participant") {
                ret = "řešitel";
            }
            if (this.get("role") === "tester") {
                ret = "tester";
            }
            if (this.get("gender") === "female") {
                ret = ret + "ka";
            }
            return ret;
        })

    });
});