define("ksi/controllers/admin/achievements", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),

        achs_persistent: _ember["default"].computed.filter('model', function (ach) {
            return ach.get("persistent");
        }),
        achs_current: _ember["default"].computed.filter('model', function (ach) {
            return !ach.get("persistent");
        }),

        actions: {
            'achievement-delete': function achievementDelete(achievement) {
                if (!confirm("Opravdu odstranit trofej " + achievement.get("title") + " a veškerá její přidělení?")) {
                    return;
                }

                achievement.set("deleting", true);
                achievement.destroyRecord().then({}, function (error) {
                    achievement.set("deleting", false);
                    alert("Trofej se nepodařilo odstranit, kontaktuj administrátora:\n" + error);
                });
            },

            'achievement-grant': function achievementGrant(achievement) {
                this.transitionTo("admin/achievement-grant", { queryParams: { achievement: achievement.id } });
            }
        }
    });
});