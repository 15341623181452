define("ksi/locales/cs/translations", ["exports"], function (exports) {
	exports["default"] = {
		"menu.tasks": "Úlohy",
		"menu.results": "Výsledky",
		"menu.news": "Novinky",
		"menu.forum": "Diskuse",
		"menu.faq": "FAQ",
		"menu.about": "O KSI",
		"menu.login": "Přihlásit se",
		"menu.register": "Registrovat se"
	};
});