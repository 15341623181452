define("ksi/routes/task", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model(params) {
            return this.store.find("task", params["task_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("title");
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set("reload_status", null);
        }
    });
});