define("ksi/initializers/ember-i18n", ["exports", "ksi/instance-initializers/ember-i18n"], function (exports, _ksiInstanceInitializersEmberI18n) {
  exports["default"] = {
    name: _ksiInstanceInitializersEmberI18n["default"].name,

    initialize: function initialize() {
      var application = arguments[1] || arguments[0]; // depending on Ember version
      if (application.instanceInitializer) {
        return;
      }

      _ksiInstanceInitializersEmberI18n["default"].initialize(application);
    }
  };
});