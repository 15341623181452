define("ksi/transforms/score-table", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
	exports["default"] = _emberData["default"].Transform.extend({
		serialize: function serialize(value) {
			return value.get("table");
		},
		deserialize: function deserialize(value) {
			return _ember["default"].Object.create({
				table: value
			});
		}
	});
});