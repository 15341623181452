define("ksi/models/profile", ["exports", "ember-data", "ksi/models/basic-profile"], function (exports, _emberData, _ksiModelsBasicProfile) {

    /* This profile extendes BasicProfile model class.
     * It contains all the information about user.
     */

    exports["default"] = _ksiModelsBasicProfile["default"].extend({
        achievements: _emberData["default"].hasMany("achievement", { async: true, defaultValue: [] }),
        score: _emberData["default"].attr("number"),
        percentile: _emberData["default"].attr("number"),
        seasons: _emberData["default"].hasMany("year", { async: true }),
        percent: _emberData["default"].attr("number"),
        results: _emberData["default"].hasMany("task-score", { defaultValue: [] }),
        tasks_num: _emberData["default"].attr("number"),

        addr_street: _emberData["default"].attr("string"),
        addr_city: _emberData["default"].attr("string"),
        addr_zip: _emberData["default"].attr("string"),
        addr_country: _emberData["default"].attr("string"),

        school_name: _emberData["default"].attr("string"),
        school_street: _emberData["default"].attr("string"),
        school_city: _emberData["default"].attr("string"),
        school_zip: _emberData["default"].attr("string"),
        school_country: _emberData["default"].attr("string"),
        school_finish: _emberData["default"].attr("number"),

        tshirt_size: _emberData["default"].attr("string"),

        notify_eval: _emberData["default"].attr("boolean"),
        notify_response: _emberData["default"].attr("boolean"),
        notify_ksi: _emberData["default"].attr("boolean"),
        notify_events: _emberData["default"].attr("boolean"),

        // my submitted tasks
        tasks: _emberData["default"].hasMany("task", { defaultValue: [], async: true })
    });
});