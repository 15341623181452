define("ksi/routes/admin/achievement-edit", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model(params) {
            return _ember["default"].RSVP.hash({
                achievement: this.store.find("achievement", params["ach_id"]),
                content: this.store.find("content", "achievements")
            });
        },
        title: "KSI: Úprava trofeje",
        actions: {
            willTransition: function willTransition() {
                this.controller.set('save_status', "");
                this.controller.set('error_status', "");
            }
        }
    });
});