define("ksi/routes/tasks", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model() {
            this.store.unloadAll("task");
            this.store.unloadAll("task-detail");
            this.store.unloadAll("module");
            return _ember["default"].RSVP.hash({
                tasks: this.store.findAll("task", "")
            });
        },
        title: "KSI: Úlohy"
    });
});