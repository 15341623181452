define("ksi/models/atask", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        title: _emberData["default"].attr("string"),
        author: _emberData["default"].belongsTo("user", { async: true }),
        co_author: _emberData["default"].belongsTo("user", { async: true }),

        git_branch: _emberData["default"].attr("string"),
        git_commit: _emberData["default"].attr("string"),
        git_path: _emberData["default"].attr("string"),
        git_create: _emberData["default"].attr("boolean"), // pouze pro ucely vytvareni nove ulohy

        deploy_date: _emberData["default"].attr("date"),
        deploy_status: _emberData["default"].attr("string"),

        max_score: _emberData["default"].attr("number"),
        wave: _emberData["default"].belongsTo("wave", { async: true }),

        eval_comment: _emberData["default"].attr("string"),

        deploy_status_default: _ember["default"].computed("deploy_status", function () {
            return this.get("deploy_status") === "default";
        }),
        deploy_status_deploying: _ember["default"].computed("deploy_status", function () {
            return this.get("deploy_status") === "deploying";
        }),
        deploy_status_done: _ember["default"].computed("deploy_status", function () {
            return this.get("deploy_status") === "done";
        }),
        deploy_status_error: _ember["default"].computed("deploy_status", function () {
            return this.get("deploy_status") === "error";
        }),
        deploy_status_diff: _ember["default"].computed("deploy_status", function () {
            return this.get("deploy_status") === "diff";
        }),

        git_commit_short: _ember["default"].computed("deploy_status", function () {
            if (this.get("git_commit")) {
                return this.get("git_commit").substring(0, 8) + "...";
            } else {
                return undefined;
            }
        }),

        git_defined: _ember["default"].computed("git_branch", "git_path", function () {
            return this.get("git_branch") && this.get("git_path");
        }),

        github_path: _ember["default"].computed("git_branch", "git_path", function () {
            return "https://github.com/fi-ksi/seminar/tree/" + this.get("git_branch") + "/" + this.get("git_path");
        })
    });
});