define("ksi/templates/admin/execs", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 26,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/admin/execs.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "clearfix");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("ID modulu");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("ID uživatele");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "submit");
          dom.setAttribute(el2, "style", "width: 250px; margin-left:0px; margin-top: 20px");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
          morphs[2] = dom.createAttrMorph(element5, 'class');
          morphs[3] = dom.createAttrMorph(element5, 'enabled');
          morphs[4] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "number", "class", "form-control", "value", ["subexpr", "@mut", [["get", "module_id", ["loc", [null, [12, 61], [12, 70]]]]], [], []], "required", true], ["loc", [null, [12, 12], [12, 86]]]], ["inline", "input", [], ["type", "number", "class", "form-control", "value", ["subexpr", "@mut", [["get", "user_id", ["loc", [null, [17, 61], [17, 68]]]]], [], []], "required", true], ["loc", [null, [17, 12], [17, 84]]]], ["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "filter_in_progress", ["loc", [null, [22, 62], [22, 80]]]], "disabled"], [], ["loc", [null, [22, 57], [22, 93]]]]]]], ["attribute", "enabled", ["concat", [["subexpr", "if", [["get", "filter_in_progress", ["loc", [null, [22, 165], [22, 183]]]], "false", "true"], [], ["loc", [null, [22, 160], [22, 200]]]]]]], ["inline", "if", [["get", "filter_in_progress", ["loc", [null, [23, 17], [23, 35]]]], "Načítám, vyčkej pár vteřin...", "Zobrazit"], [], ["loc", [null, [23, 12], [23, 80]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 16
                  },
                  "end": {
                    "line": 57,
                    "column": 101
                  }
                },
                "moduleName": "ksi/templates/admin/execs.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "exec.user.first_name", ["loc", [null, [57, 53], [57, 77]]]], ["content", "exec.user.last_name", ["loc", [null, [57, 78], [57, 101]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 10
                  },
                  "end": {
                    "line": 69,
                    "column": 10
                  }
                },
                "moduleName": "ksi/templates/admin/execs.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "colspan", "6");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("label");
                var el4 = dom.createTextNode("Řešitelův kód");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 3, 3);
                return morphs;
              },
              statements: [["inline", "code-editor", [], ["content", ["subexpr", "@mut", [["get", "exec.code", ["loc", [null, [67, 36], [67, 45]]]]], [], []], "id", ["subexpr", "construct-id", ["exec_code", ["get", "exec.id", ["loc", [null, [67, 75], [67, 82]]]]], [], ["loc", [null, [67, 49], [67, 83]]]]], ["loc", [null, [67, 14], [67, 85]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 10
                  },
                  "end": {
                    "line": 76,
                    "column": 10
                  }
                },
                "moduleName": "ksi/templates/admin/execs.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "colspan", "6");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("label");
                var el4 = dom.createTextNode("Orgovský report");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 3, 3);
                return morphs;
              },
              statements: [["inline", "textarea", [], ["class", "form-control", "style", "min-width: 100%;", "rows", "10", "readonly", "true", "value", ["subexpr", "@mut", [["get", "exec.report", ["loc", [null, [74, 103], [74, 114]]]]], [], []]], ["loc", [null, [74, 14], [74, 116]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 8
                },
                "end": {
                  "line": 77,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/admin/execs.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "btn btn-primary inline");
              var el4 = dom.createTextNode("Kód");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "btn btn-primary inline");
              var el4 = dom.createTextNode("Report");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [11]);
              var element2 = dom.childAt(element1, [1]);
              var element3 = dom.childAt(element1, [3]);
              var morphs = new Array(10);
              morphs[0] = dom.createAttrMorph(element0, 'style');
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[4] = dom.createUnsafeMorphAt(dom.childAt(element0, [7]), 0, 0);
              morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
              morphs[6] = dom.createElementMorph(element2);
              morphs[7] = dom.createElementMorph(element3);
              morphs[8] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[9] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "style", ["concat", ["height:45px; background-color:\n            ", ["subexpr", "if", [["get", "exec.ok", ["loc", [null, [49, 17], [49, 24]]]], "#C0FFB0"], [], ["loc", [null, [49, 12], [49, 36]]]], "\n            ", ["subexpr", "if", [["get", "exec.error", ["loc", [null, [50, 17], [50, 27]]]], "#FDD5E5"], [], ["loc", [null, [50, 12], [50, 39]]]], "\n            ", ["subexpr", "if", [["get", "exec.nok", ["loc", [null, [51, 17], [51, 25]]]], "#FFEFD4"], [], ["loc", [null, [51, 12], [51, 37]]]], "\n            "]]], ["content", "exec.id", ["loc", [null, [53, 16], [53, 27]]]], ["inline", "date-and-time", [["get", "exec.time", ["loc", [null, [54, 32], [54, 41]]]], "cs"], [], ["loc", [null, [54, 16], [54, 48]]]], ["content", "exec.result", ["loc", [null, [55, 16], [55, 31]]]], ["content", "exec.module.name", ["loc", [null, [56, 16], [56, 38]]]], ["block", "link-to", ["user-profile", ["get", "exec.user", ["loc", [null, [57, 42], [57, 51]]]]], [], 0, null, ["loc", [null, [57, 16], [57, 113]]]], ["element", "action", ["code", ["get", "exec", ["loc", [null, [59, 69], [59, 73]]]]], [], ["loc", [null, [59, 53], [59, 75]]]], ["element", "action", ["report", ["get", "exec", ["loc", [null, [60, 71], [60, 75]]]]], [], ["loc", [null, [60, 53], [60, 77]]]], ["block", "if", [["get", "exec.show_code", ["loc", [null, [64, 16], [64, 30]]]]], [], 1, null, ["loc", [null, [64, 10], [69, 17]]]], ["block", "if", [["get", "exec.show_report", ["loc", [null, [71, 16], [71, 32]]]]], [], 2, null, ["loc", [null, [71, 10], [76, 17]]]]],
            locals: ["exec"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 4
              },
              "end": {
                "line": 92,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/admin/execs.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "table table-striped");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            dom.setAttribute(el3, "style", "height:45px");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("ID");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Čas");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Výsledek");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Modul");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Uživatel");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Akce");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-info");
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("Upozornění:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" zobrazeno nejvýše posledních 20\n      z celkových ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" spuštění.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-info");
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("Vysvětlení:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" stav ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            var el3 = dom.createTextNode("error");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" značí, že při\n      spouštění kódu došlo k chybě v některém z organizátorksých skriptů. Stav ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            var el3 = dom.createTextNode("ok");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" značí,\n      že k chybě nedošlo. Při spouštění se nespouští ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            var el3 = dom.createTextNode("eval");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" skript, takže\n      se nekontroluje, zda-li byl řešitelův program správně. Výsledek by tedy měl\n      být v hotovém modulu vždy ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            var el3 = dom.createTextNode("ok");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(".\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 2, 2);
            return morphs;
          },
          statements: [["block", "each", [["get", "execs_sorted", ["loc", [null, [47, 16], [47, 28]]]]], [], 0, null, ["loc", [null, [47, 8], [77, 17]]]], ["content", "total", ["loc", [null, [83, 18], [83, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 92,
                "column": 4
              },
              "end": {
                "line": 94,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/admin/execs.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "ale-rt", [], ["type", "warning", "text", "Zatím žádná spuštění."], ["loc", [null, [93, 6], [93, 60]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 2
            },
            "end": {
              "line": 95,
              "column": 3
            }
          },
          "moduleName": "ksi/templates/admin/execs.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "execs_sorted", ["loc", [null, [32, 10], [32, 22]]]]], [], 0, 1, ["loc", [null, [32, 4], [94, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 97,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/admin/execs.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "admin-evals");
        dom.setAttribute(el1, "class", "container content-section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("Spuštění kódu");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-default");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading");
        var el4 = dom.createTextNode("Zvolit omezení");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [3, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(element6, 5, 5);
        return morphs;
      },
      statements: [["block", "validatable-form", [], ["action", "filter"], 0, null, ["loc", [null, [8, 6], [26, 27]]]], ["block", "unless", [["get", "filter_in_progress", ["loc", [null, [31, 12], [31, 30]]]]], [], 1, null, ["loc", [null, [31, 2], [95, 14]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});