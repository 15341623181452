define('ksi/initializers/ember-cli-dates', ['exports', 'ember', 'ember-cli-dates/helpers/time-format', 'ember-cli-dates/helpers/time-ago-in-words', 'ember-cli-dates/helpers/day-of-the-week', 'ember-cli-dates/helpers/time-ahead-in-words', 'ember-cli-dates/helpers/time-delta-in-words', 'ember-cli-dates/helpers/month-and-year', 'ember-cli-dates/helpers/month-and-day', 'ember-cli-dates/helpers/date-and-time'], function (exports, _ember, _emberCliDatesHelpersTimeFormat, _emberCliDatesHelpersTimeAgoInWords, _emberCliDatesHelpersDayOfTheWeek, _emberCliDatesHelpersTimeAheadInWords, _emberCliDatesHelpersTimeDeltaInWords, _emberCliDatesHelpersMonthAndYear, _emberCliDatesHelpersMonthAndDay, _emberCliDatesHelpersDateAndTime) {
  var initialize = function initialize() /* container, app */{
    _ember['default'].Handlebars.helper('time-format', _emberCliDatesHelpersTimeFormat.timeFormat);
    _ember['default'].Handlebars.helper('time-ago-in-words', _emberCliDatesHelpersTimeAgoInWords.timeAgoInWords);
    _ember['default'].Handlebars.helper('day-of-the-week', _emberCliDatesHelpersDayOfTheWeek.dayOfTheWeek);
    _ember['default'].Handlebars.helper('time-ahead-in-words', _emberCliDatesHelpersTimeAheadInWords.timeAheadInWords);
    _ember['default'].Handlebars.helper('time-delta-in-words', _emberCliDatesHelpersTimeDeltaInWords.timeDeltaInWords);
    _ember['default'].Handlebars.helper('month-and-year', _emberCliDatesHelpersMonthAndYear.monthAndYear);
    _ember['default'].Handlebars.helper('month-and-day', _emberCliDatesHelpersMonthAndDay.monthAndDay);
    _ember['default'].Handlebars.helper('date-and-time', _emberCliDatesHelpersDateAndTime.dateAndTime);
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'ember-cli-dates',
    initialize: initialize
  };
});