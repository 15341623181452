define("ksi/routes/admin/achievement-create", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
  exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
    model: function model() {
      return this.store.find("content", "achievements");
    },
    title: "KSI: Nová trofej",
    actions: {
      willTransition: function willTransition() {
        this.controller.set('save_status', "");
        this.controller.set('error_status', "");
      }
    }
  });
});