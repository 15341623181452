define("ksi/controllers/results", ["exports", "ember", "ksi/config/environment"], function (exports, _ember, _ksiConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),

        calc_score_list: function calc_score_list(data) {
            var groups = new Map();
            var score_list = [];
            data.forEach(function (user) {
                var score = user.get("score");
                if (groups.has(score)) {
                    groups.get(score).push(user);
                } else {
                    groups.set(score, [user]);
                }
                if (score_list.length === 0 || score_list[score_list.length - 1] !== score) {
                    score_list.push(score);
                }
            });

            var result = [];
            var position = 1;
            score_list.forEach(function (num) {
                var list = groups.get(num);
                var notused = true;
                list.sort(function (a, b) {
                    return a.get("tasks_num") < b.get("tasks_num");
                });
                list.forEach(function (user) {
                    var o = {
                        "user": user
                    };
                    if (list.length === 1) {
                        o["num"] = position;
                    } else {
                        if (notused) {
                            o["num"] = position.toString() + "–" + (position + list.length - 1).toString() + ".";
                        } else {
                            o["num"] = "";
                        }
                    }
                    result.push(o);
                });
                position += list.length;
            });
            return result;
        },

        results_hs: _ember["default"].computed("model.part_hs", function () {
            return this.calc_score_list(this.get("model.part_hs"));
        }),

        results_other: _ember["default"].computed("model.part_other", function () {
            return this.calc_score_list(this.get("model.part_other"));
        }),

        actions: {
            'export-users': function exportUsers() {
                var self = this;
                this.set('export_loading', true);
                this.get('session').authorize('authorizer:oauth2', function (header, content) {
                    var request = new XMLHttpRequest();
                    request.open("GET", _ksiConfigEnvironment["default"].API_LOC + "/admin/user-export", true);
                    request.responseType = "blob";
                    request.setRequestHeader(header, content);
                    request.setRequestHeader('year', self.get("session.year"));
                    request.onload = function () {
                        self.set('export_loading', false);
                        if (this.status === 200) {
                            var file = window.URL.createObjectURL(this.response);
                            var a = document.createElement("a");
                            a.href = file;
                            a.download = this.response.name || "ksi_resitele.csv";
                            document.body.appendChild(a);
                            a.click();
                            window.onfocus = function () {
                                document.body.removeChild(a);
                            };
                        }
                    };
                    request.send();
                });
            }
        }

    });
});