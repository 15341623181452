define("ksi/models/article", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        title: _emberData["default"].attr("string"),
        time_published: _emberData["default"].attr("date"),
        picture: _emberData["default"].attr("string"),
        body: _emberData["default"].attr("string"),
        published: _emberData["default"].attr("boolean"),
        resource: _emberData["default"].attr("string"),
        content: _emberData["default"].belongsTo("content", { async: true }),
        year: _emberData["default"].belongsTo("year", { async: true }),

        published_str: _ember["default"].computed("published", function () {
            if (this.get("published")) {
                return "ano";
            } else {
                return "ne";
            }
        }),
        intro: _ember["default"].computed("body", function () {
            var begin = this.get("body").indexOf("<p>");
            var end = this.get("body").indexOf("</p>");
            if (begin === -1 || end === -1) {
                return "";
            }

            return this.get("body").substr(begin, end);
        })
    });
});