define("ksi/components/header-component", ["exports", "ember", "ksi/config/environment"], function (exports, _ember, _ksiConfigEnvironment) {
    exports["default"] = _ember["default"].Component.extend({
        session: _ember["default"].inject.service(),
        didInsertElement: function didInsertElement() {
            this._super();
            this.set("error_message", undefined);
        },

        actions: {
            logout: function logout() {
                var self = this;
                this.get('session').authorize('authorizer:oauth2', function (header, content) {
                    _ember["default"].$.ajax({
                        url: _ksiConfigEnvironment["default"].API_LOC + "/logout",
                        data: {},
                        contentType: "application/json",
                        type: 'GET',
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader(header, content);
                        },
                        success: function success() {
                            self.get("session").invalidate();
                        },
                        error: function error() {
                            self.get("session").invalidate();
                        }
                    });
                });
            },

            changeYear: function changeYear(newyear) {
                this.get('session').changeYear(newyear);
                this._controller.send("reload");
            }
        }
    });
});