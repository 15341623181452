define("ksi/routes/about", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Route.extend({
        beforeModel: function beforeModel(param) {
            this._super();
            if ("queryParams" in param && "view" in param.queryParams && param.queryParams.view === "faq") {
                _ember["default"].$("#faq").livequery(function () {
                    _ember["default"].$('html, body').animate({
                        scrollTop: _ember["default"].$("#faq").offset().top - 150 //Magic constant
                    }, "slow");
                });
            } else {
                _ember["default"].$("#faq").expire();
                _ember["default"].$("html, body").animate({ scrollTop: 0 }, "slow");
            }
        },
        queryParams: {
            view: {
                refreshModel: true,
                replace: true,
                as: "view"
            }
        },
        view: null,
        title: "O KSI"
    });
});