define("ksi/templates/components/module-wrap", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 7,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/components/module-wrap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      (");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "module.id", ["loc", [null, [6, 7], [6, 20]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 11,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/components/module-wrap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "glyphicon glyphicon-ok");
          dom.setAttribute(el1, "aria-hidden", "true");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/components/module-wrap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "glyphicon glyphicon-remove");
          dom.setAttribute(el1, "aria-hidden", "true");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 6
            },
            "end": {
              "line": 20,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/components/module-wrap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "float: right; margin-top: -5px;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-task btn-primary hidden-print");
          var el3 = dom.createTextNode("Zkusit odevzdat znovu");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["element", "action", ["show"], [], ["loc", [null, [18, 62], [18, 79]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 6
              },
              "end": {
                "line": 27,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "code-module", [], ["module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [26, 29], [26, 35]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [26, 42], [26, 47]]]]], [], []], "id", ["subexpr", "@mut", [["get", "module.id", ["loc", [null, [26, 51], [26, 60]]]]], [], []], "submit_done", "submit_done", "submit_succ_done", "submit_succ_done", "actionReceiver", ["subexpr", "@mut", [["get", "module_logic", ["loc", [null, [26, 138], [26, 150]]]]], [], []], "opened", ["subexpr", "@mut", [["get", "opened", ["loc", [null, [26, 158], [26, 164]]]]], [], []]], ["loc", [null, [26, 8], [26, 166]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 30,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "quiz-module", [], ["module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [29, 29], [29, 35]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [29, 42], [29, 47]]]]], [], []], "submit_done", "submit_done", "submit_succ_done", "submit_succ_done", "actionReceiver", ["subexpr", "@mut", [["get", "module_logic", ["loc", [null, [29, 125], [29, 137]]]]], [], []], "opened", ["subexpr", "@mut", [["get", "opened", ["loc", [null, [29, 145], [29, 151]]]]], [], []]], ["loc", [null, [29, 8], [29, 153]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "file-module", [], ["module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [32, 29], [32, 35]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [32, 42], [32, 47]]]]], [], []], "submit_done", "submit_done", "submit_succ_done", "submit_succ_done", "actionReceiver", ["subexpr", "@mut", [["get", "module_logic", ["loc", [null, [32, 125], [32, 137]]]]], [], []], "opened", ["subexpr", "@mut", [["get", "opened", ["loc", [null, [32, 145], [32, 151]]]]], [], []]], ["loc", [null, [32, 8], [32, 153]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 6
              },
              "end": {
                "line": 36,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "sortable-module", [], ["module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [35, 33], [35, 39]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [35, 46], [35, 51]]]]], [], []], "submit_done", "submit_done", "submit_succ_done", "submit_succ_done", "actionReceiver", ["subexpr", "@mut", [["get", "module_logic", ["loc", [null, [35, 129], [35, 141]]]]], [], []], "opened", ["subexpr", "@mut", [["get", "opened", ["loc", [null, [35, 149], [35, 155]]]]], [], []]], ["loc", [null, [35, 8], [35, 157]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "text-module", [], ["module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [38, 29], [38, 35]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [38, 42], [38, 47]]]]], [], []], "submit_done", "submit_done", "submit_succ_done", "submit_succ_done", "actionReceiver", ["subexpr", "@mut", [["get", "module_logic", ["loc", [null, [38, 125], [38, 137]]]]], [], []], "opened", ["subexpr", "@mut", [["get", "opened", ["loc", [null, [38, 145], [38, 151]]]]], [], []]], ["loc", [null, [38, 8], [38, 153]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 14
                  },
                  "end": {
                    "line": 46,
                    "column": 14
                  }
                },
                "moduleName": "ksi/templates/components/module-wrap.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "textarea", [], ["class", "form-control", "style", "min-width: 100%;", "rows", "10", "readonly", "true", "value", ["subexpr", "@mut", [["get", "module.report_output", ["loc", [null, [45, 107], [45, 127]]]]], [], []]], ["loc", [null, [45, 18], [45, 129]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 8
                },
                "end": {
                  "line": 48,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/components/module-wrap.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              dom.setAttribute(el1, "style", "margin-top:20px; margin-left:0px; margin-right:20px");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              dom.setAttribute(el2, "class", "btn btn-primary btn-task inline hidden-print");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["element", "action", ["toggle_report"], [], ["loc", [null, [43, 75], [43, 101]]]], ["content", "report_button_text", ["loc", [null, [43, 102], [43, 124]]]], ["block", "if", [["get", "module.show_report", ["loc", [null, [44, 20], [44, 38]]]]], [], 0, null, ["loc", [null, [44, 14], [46, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 6
              },
              "end": {
                "line": 49,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "module.report_output", ["loc", [null, [41, 14], [41, 34]]]]], [], 0, null, ["loc", [null, [41, 8], [48, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 8
                },
                "end": {
                  "line": 53,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/components/module-wrap.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-task btn-primary hidden-print mtop20");
              var el2 = dom.createTextNode("Odevzdat");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["element", "action", ["submit"], [], ["loc", [null, [52, 73], [52, 92]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 8
                },
                "end": {
                  "line": 55,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/components/module-wrap.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-task btn-primary hidden-print disabled mtop20");
              var el2 = dom.createTextNode("Odevzdávám...");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 8
                },
                "end": {
                  "line": 56,
                  "column": 119
                }
              },
              "moduleName": "ksi/templates/components/module-wrap.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Mám problém");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 6
              },
              "end": {
                "line": 57,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/module-wrap.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "submitting", ["loc", [null, [51, 18], [51, 28]]]]], [], 0, 1, ["loc", [null, [51, 8], [55, 19]]]], ["block", "link-to", ["task.discussion"], ["tagName", "button", "class", "btn btn-task btn-primary hidden-print mtop20"], 2, null, ["loc", [null, [56, 8], [56, 131]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 2
            },
            "end": {
              "line": 59,
              "column": 2
            }
          },
          "moduleName": "ksi/templates/components/module-wrap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "panel-body");
          dom.setAttribute(el1, "id", "task-module-body");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createMorphAt(element3, 2, 2);
          morphs[2] = dom.createMorphAt(element3, 3, 3);
          morphs[3] = dom.createMorphAt(element3, 4, 4);
          morphs[4] = dom.createMorphAt(element3, 5, 5);
          morphs[5] = dom.createMorphAt(element3, 6, 6);
          morphs[6] = dom.createMorphAt(element3, 7, 7);
          return morphs;
        },
        statements: [["block", "if", [["get", "module.is_programming", ["loc", [null, [25, 12], [25, 33]]]]], [], 0, null, ["loc", [null, [25, 6], [27, 13]]]], ["block", "if", [["get", "module.is_quiz", ["loc", [null, [28, 12], [28, 26]]]]], [], 1, null, ["loc", [null, [28, 6], [30, 13]]]], ["block", "if", [["get", "module.is_general", ["loc", [null, [31, 12], [31, 29]]]]], [], 2, null, ["loc", [null, [31, 6], [33, 13]]]], ["block", "if", [["get", "module.is_sortable", ["loc", [null, [34, 12], [34, 30]]]]], [], 3, null, ["loc", [null, [34, 6], [36, 13]]]], ["block", "if", [["get", "module.is_text", ["loc", [null, [37, 12], [37, 26]]]]], [], 4, null, ["loc", [null, [37, 6], [39, 13]]]], ["block", "if", [["get", "session.current_user.organisator", ["loc", [null, [40, 12], [40, 44]]]]], [], 5, null, ["loc", [null, [40, 6], [49, 13]]]], ["block", "if", [["get", "opened", ["loc", [null, [50, 12], [50, 18]]]]], [], 6, null, ["loc", [null, [50, 6], [57, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/components/module-wrap.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "margin-left:0px");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createAttrMorph(element6, 'class');
        morphs[2] = dom.createUnsafeMorphAt(element6, 1, 1);
        morphs[3] = dom.createMorphAt(element6, 3, 3);
        morphs[4] = dom.createMorphAt(element6, 5, 5);
        morphs[5] = dom.createMorphAt(element6, 6, 6);
        morphs[6] = dom.createMorphAt(element6, 8, 8);
        morphs[7] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["panel panel-module-", ["subexpr", "module-type", [["get", "module.state", ["loc", [null, [1, 45], [1, 57]]]]], [], ["loc", [null, [1, 31], [1, 59]]]]]]], ["attribute", "class", ["concat", ["panel-heading panel-heading-module-", ["subexpr", "module-type", [["get", "module.state", ["loc", [null, [2, 63], [2, 75]]]]], [], ["loc", [null, [2, 49], [2, 77]]]]]]], ["content", "module.name", ["loc", [null, [3, 6], [3, 23]]]], ["block", "if", [["get", "session.current_user.organisator", ["loc", [null, [5, 12], [5, 44]]]]], [], 0, null, ["loc", [null, [5, 6], [7, 13]]]], ["block", "if", [["get", "module.is_correct", ["loc", [null, [9, 12], [9, 29]]]]], [], 1, null, ["loc", [null, [9, 6], [11, 13]]]], ["block", "if", [["get", "module.is_incorrect", ["loc", [null, [12, 12], [12, 31]]]]], [], 2, null, ["loc", [null, [12, 6], [14, 13]]]], ["block", "unless", [["get", "show", ["loc", [null, [16, 16], [16, 20]]]]], [], 3, null, ["loc", [null, [16, 6], [20, 17]]]], ["block", "if", [["get", "show", ["loc", [null, [23, 8], [23, 12]]]]], [], 4, null, ["loc", [null, [23, 2], [59, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});