define('ksi/mixins/admin-adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ksi/config/environment', 'ember'], function (exports, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _ksiConfigEnvironment, _ember) {
    exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
        authorizer: 'authorizer:oauth2',
        namespace: 'admin',
        host: _ksiConfigEnvironment['default'].API_LOC,

        headers: _ember['default'].computed('session.year', function () {
            var year = this.get('session.year');
            if (year) {
                return { 'year': year };
            } else {
                return {};
            }
        })

    });
});