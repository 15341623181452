define("ksi/routes/article", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model(params) {
            return this.store.find("article", params["article_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("title");
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        }
    });
});