define("ksi/models/module", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        type: _emberData["default"].attr("string"),
        is_quiz: _ember["default"].computed("type", function () {
            return this.get("type") === "quiz";
        }),
        is_programming: _ember["default"].computed("type", function () {
            return this.get("type") === "programming";
        }),
        is_general: _ember["default"].computed("type", function () {
            return this.get("type") === "general";
        }),
        is_sortable: _ember["default"].computed("type", function () {
            return this.get("type") === "sortable";
        }),
        is_text: _ember["default"].computed("type", function () {
            return this.get("type") === "text";
        }),

        name: _emberData["default"].attr("string"),
        description: _emberData["default"].attr("string"),
        state: _emberData["default"].attr("string"),
        max_score: _emberData["default"].attr("number"),
        score: _emberData["default"].belongsTo("module-score", { "default": null }),

        is_blank: _ember["default"].computed("state", function () {
            return this.get("state") === "blank";
        }),
        is_correct: _ember["default"].computed("state", function () {
            return this.get("state") === "correct";
        }),
        is_incorrect: _ember["default"].computed("state", function () {
            return this.get("state") === "incorrect";
        }),

        // For sortable
        sortable_list: _emberData["default"].attr("sortable-list"),

        // For quiz
        questions: _emberData["default"].attr("quiz-questions"),

        // For code
        code: _emberData["default"].attr("string"),
        default_code: _emberData["default"].attr("string"),
        last_datetime: _emberData["default"].attr("date"),
        last_origin: _emberData["default"].attr("string"),

        last_origin_text: _ember["default"].computed("last_origin", function () {
            if (this.get("last_origin") === "execution") {
                return "spuštění";
            }
            if (this.get("last_origin") === "evaluation") {
                return "úspěšného odevzdání"; // not strictly true -if no successful eval, then the last eval
            }
            return "pokusu";
        }),

        // For general
        submitted_files: _emberData["default"].attr("files-list"),

        // For text
        fields: _emberData["default"].attr("raw")
    });
});