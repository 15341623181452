define("ksi/templates/about", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 147,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/about.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "container content-section autojustify");
        dom.setAttribute(el1, "style", "margin-bottom:40px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "id", "about");
        var el3 = dom.createTextNode("O KSI");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("KSI, neboli Korespondenční seminář z informatiky, je celoroční soutěž organizovaná primárně studenty Fakulty informatiky Masarykovy univerzity. Cílem semináře je seznámit řešitele se zajímavými oblastmi informatiky a procvičit programátorské, matematické a logické myšlení.\n    Seminář je uzpůsoben jak pro úplné začátečníky, kteří si na jednoduchých příkladech procvičí danou problematiku, tak pro zkušenější řešitele, kteří se pokusí pokořit hlavní soutěžní úlohy.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("Jak seminář probíhá?");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "name", "jakSeminarProbiha");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Středobodem semináře je graf úloh, který se snažíš projít a po cestě vyřešit co nejvíce úloh zvládneš.\n    Některé části grafu představují základní myšlenky, pojmy a postupy a obsahují jednoduché úlohy, jejichž správnost se vyhodnocuje okamžitě automaticky.\n    Jiné obsahují hlavní soutěžní úlohy, na jejichž vyřešení a sepsání máš zhruba jeden měsíc.\n    Po termínu odevzdání se úloha uzamkne, my tvoje řešení opravíme, přidělíme ti za něj body, poskytneme ti k němu zpětnou vazbu a zveřejníme vzorové řešení úlohy.\n    Soutěžní úlohy jsou pak různého charakteru a zatímco nejjednodušší úlohy by po projití úvodních uzlů grafu měl zvládnout každý, nad tou nejtěžší se může zapotit i ostřílený řešitel programátorských soutěží.\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    S ostatními účastníky i organizátory semináře se můžeš během roku potkat na několika výletech.\n    Koncem školního roku pak vyhlásíme výsledky a nejlepší řešitele pozveme na ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "https://kscuk.fi.muni.cz/");
        var el4 = dom.createTextNode("týdenní soustředění K-SCUK");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".\n    Úspěšní řešitelé semináře (ti, kteří dosáhnou alespoň 60 % celkového počtu bodů) mají možnost si nechat prominout přijímací zkoušku na Fakultu informatiky MU. (");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "/o-ksi?view=about#prominutiPrijimacek");
        var el4 = dom.createTextNode("podrobněji níže");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(")\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("Jaké úlohy mám očekávat?");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "name", "jakeUlohyOcekavat");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Tvým úkolem ve většině úloh bude řešení algoritmického či programátorského problému.\n    Někdy bude stačit popsat řešení jen obecně pomocí pseudokódu, jindy po tobě budeme chtít i program v Pythonu.\n    Pokud programovat neumíš nebo programuješ v jiném jazyce, nemusíš se ničeho bát.\n    Python tě pomocí cvičných příkladů rychle naučíme.\n    Vždyť přece kolik jazyků umíš, tolikrát jsi člověkem. ;)\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Některé z našich úloh mohou být složité nebo pracné, i když s dobrým nápadem se většinou dají vyřešit rychle a elegantně. Není třeba vyřešit všechny úlohy – pošli nám, co zvládneš, a zbytek si po uzavření úlohy můžeš přečíst ve vzorovém řešení. Také není nutné, abys měl(a) úlohu kompletně dořešenou. I pokud máš jen nějaké nápady co s ní, napiš nám je a my tvoji snahu určitě oceníme.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        var el3 = dom.createTextNode("Proč se účastnit?");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "name", "procSeUcastnit");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Procvičíš si programátorské, matematické a logické myšlení a schopnost řešit nelehké problémy s využitím počítače. Získáš nové znalosti a naučíš se algoritmy běžně používané v každodenní programátorské praxi.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Naučíš se své myšlenky jasně formulovat a srozumitelně vyjadřovat.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Připravíš se na programátorské i jiné soutěže, např. na matematickou olympiádu kategorie P, ale také na budoucí přijímací zkoušky či pohovory. Trénink je základ úspěchu!");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Přiblíží se ti látka vyučovaná na vysokých školách. Dokážeš se lépe rozhodnout, co chceš studovat, a pokud se rozhodneš pro informatiku, získáš mírný náskok na své budoucí spolužáky.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Budeš-li patřit mezi úspěšné řešitele, můžeš se těšit na přijetí na Fakultu informatiky MU bez přijímacích zkoušek. (");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "/o-ksi?view=about#prominutiPrijimacek");
        var el6 = dom.createTextNode("podrobněji níže");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("A hlavně, když budeš svědomitě řešit, jistě tě nemine odměna v podobě ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "https://kscuk.fi.muni.cz/");
        var el6 = dom.createTextNode("týdenního soustředění");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(".");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "id", "rules");
        var el3 = dom.createTextNode("Pravidla semináře");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "name", "pravidla");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    Při řešení úloh, ať už malých, či velkých, dodržuj etická pravidla semináře:\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ol");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createElement("strong");
        var el6 = dom.createTextNode("Všechna řešení musí být vypracována samostatně.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Opisování, stejně jako vyzrazení řešení, bude hodnoceno 0 body a odebráním možnosti stát se v aktuálním ročníku úspěšným řešitelem (za úspěšné považujeme jen ty, kteří nepodvádějí).");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("S kamarády nebo třeba učiteli můžeš o úloze diskutovat, ale jen do míry ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        var el6 = dom.createTextNode("encyklopedických znalostí");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(". Můžeš se tedy třeba bavit o tom, jakou složitost má algoritmus Quick sort, ale nemůžeš společně s kamarádem řešit programovací úlohu.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createElement("strong");
        var el6 = dom.createTextNode("Externí zdroje cituj.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Pokud používáš při řešení nějaký externí zdroj (literaturu, webovou stránku, konzultaci s kamarádem, apod.) a čerpáš odtud nějakou klíčovou myšlenku, nebo třeba kus textu, musíš tento zdroj uvést formou odkazu, na základě kterého budeme moct tento zdroj dohledat.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "id", "calendar");
        var el3 = dom.createTextNode("Kalendář akcí KSI");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "somSlovak");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    Významné akce KSI, jako Ksílety nebo termíny odevzdání úloh, zaznamenáváme do\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "https://calendar.google.com/calendar/embed?src=07cn4qi2qm1g3rtpuls78dmsbg%40group.calendar.google.com");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createTextNode("Google kalendáře KSI");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(". Tento kalendář si můžeš ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "https://calendar.google.com/calendar/render?cid=07cn4qi2qm1g3rtpuls78dmsbg@group.calendar.google.com#main_7");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createTextNode("importovat");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "id", "faq");
        var el3 = dom.createTextNode("FAQ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "somSlovak");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Som Slovák. Môžem písať riešenie v slovenčine?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Jistě. V organizátorském týmu máme dokonce několik Slováků, takže se slovenština ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("strike");
        var el5 = dom.createTextNode("občas objeví");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" pravidelně objevuje i v zadání.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "kdoJeUspesnyResitel");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Kdo je úspěšný řešitel semináře?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Kdokoliv, kdo dosáhne alespoň 60 % celkového počtu bodů.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "prvniVlnaPryc");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Dozvěděl jsem se o semináři pozdě, první vlna už proběhla. Je možné se ještě přihlásit do semináře?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Jistě, do semináře se můžeš přihlásit kdykoli. Pouze se snižuje tvá šance stát se úspěšným řešitelem.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "prominutiPrijimacek");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Budu-li úspěšný řešitel KSI např. v 2. ročníku, budu po maturitě přijat na FI bez přijímaček?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Na většinu oborů téměř určitě ano. Jsou ovšem dvě výjimky:\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("na FI jsou nově otevřeny víceoborová studia s velmi omezeným počtem míst a nejsou na ně promíjeny přijímačky,");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("tvé úspěšné řešitelství nesmí být starší dvou let. Pokud by ses stal úspěšným řešitelem už v 1. ročníku střední, toto úspěšné řešitelství by ti přijímačky neprominulo. Od druháku dál už ano.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Organizátoři KSI ale nemohou hovořit za fakultu v otázce přijímacího řízení, proto doporučujeme zjistit aktuální informace na ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://www.fi.muni.cz/admission/info-bachelor.html.cs");
        var el5 = dom.createTextNode("oficiálních stránkách Fakulty informatiky MU");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(".\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "studentStredniSkoly");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Mohu řešit KSI i když nejsem student střední školy?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Ano, KSI může řešit kdokoli.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "prominutiPrijimacekMaturitant");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Jsem v maturitním ročníku, mám šanci být přijat na FI skrze vyřešení KSI?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: Ano, seminář ukončujeme vždy tak, abychom stihli vedení FI poslat seznam úspěšných řešitelů před uzavřením přijímacího řízení.\n      Podej si žádost o prominutí přijímací zkoušky v řádném termínu. Pokud se staneš úspěšným řešitelem KSI a hlásíš se na jednooborové studium, budeš určitě přijat.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel panel-ksi");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "name", "kdoOrganizujeKSI");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-heading panel-heading-ksi");
        var el4 = dom.createTextNode("Q: Kdo KSI organizuje a podporuje?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n      A: KSI je organizováno studenty a pedagogy Fakulty informatiky Masarykovy univerzity. Mezi organizátory KSI jsou též studenti jiný vysokých škol. \n      KSI má podporu v rámci projektu na podporu nadaných žáků základních a středních škol vypsaného MŠMT. Projekt je tedy spolufinancován státním rozpočtem České republiky.\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Máš jinou otázku, na kterou ti zde chybí odpověď? Neváhej nám napsat na ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "mailto:ksi@fi.muni.cz");
        var el4 = dom.createTextNode("ksi@fi.muni.cz");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});