define("ksi/models/exec", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        code: _emberData["default"].attr("string"),
        module: _emberData["default"].belongsTo("module", { async: true }),
        report: _emberData["default"].attr("string"),
        result: _emberData["default"].attr("string"),
        time: _emberData["default"].attr("date"),
        user: _emberData["default"].belongsTo("user", { async: true }),

        ok: _ember["default"].computed("result", function () {
            return this.get("result") === "ok";
        }),
        nok: _ember["default"].computed("result", function () {
            return this.get("result") === "nok";
        }),
        error: _ember["default"].computed("result", function () {
            return this.get("result") === "error";
        })
    });
});