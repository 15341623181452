define('ksi/components/text-module', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'ksi/config/environment', 'moment'], function (exports, _ember, _emberComponentInboundActionsInboundActions, _ksiConfigEnvironment, _moment) {
    exports['default'] = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], {
        tagName: "",
        classNames: [],
        session: _ember['default'].inject.service(),
        general_error: "",
        show_message: false,
        script_message_output: undefined,
        script_message_mode: "danger",

        fields: _ember['default'].computed("module.fields", function () {
            if (!this.get("module.fields")) {
                return [];
            }
            var i = 0;
            return this.get("module.fields").map(function (field) {
                return _ember['default'].Object.create({
                    'index': i++,
                    'text': field
                });
            });
        }),

        actions: {
            submit: function submit() {
                var self = this;
                this.set("general_error", "");
                self.set("module.show_report", false);
                self.set("script_message_output", null);
                if (!this.get("text")) {
                    this.set("text", "");
                }

                var data = [];
                for (var i = 0; i < this.get("module.fields.length"); i++) {
                    data.push(_ember['default'].$("#module_text_" + this.get("module.id") + "_" + i).val());
                }
                this.get('session').authorize('authorizer:oauth2', function (header, h) {
                    _ember['default'].$.ajax({
                        url: _ksiConfigEnvironment['default'].API_LOC + "/modules/" + self.get("module.id") + "/submit",
                        data: JSON.stringify({ content: data }),
                        contentType: "application/json",
                        type: 'POST',
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader(header, h);
                        },
                        success: function success(data) {
                            if ("result" in data) {
                                self.set("script_message_mode", "danger");
                                self.set("module.blockClosing", false);
                                if (!self.get("module.score")) {
                                    self.set("module.score", self.get("store").createRecord("module-score"));
                                }
                                if (data.score !== undefined) {
                                    self.set("module.score.score", data.score);
                                }
                                if ("error" in data) {
                                    self.set("module.show_report", true);
                                    self.set("general_error", data.error);
                                }
                                if (data.result === "ok") {
                                    self.set("script_message_mode", "success");
                                    self.set("module.state", "correct");
                                    if ("message" in data && data.message.trim() !== "") {
                                        self.set("module.blockClosing", true);
                                    }
                                    self.sendAction("submit_succ_done");
                                } else if (data.result === "nok") {
                                    if (self.get("module.state") !== "correct") {
                                        self.set("module.state", "incorrect");
                                    }
                                    if (!("message" in data && data.message.trim() !== "")) {
                                        self.set("script_message_output", "Tvé řešení není správné! Zkus to znovu.");
                                    }
                                } else if (!("error" in data)) {
                                    self.set("module.show_report", true);
                                    self.set("general_error", "Server odeslal neznámý result, kontaktuj organizátora.");
                                }
                            } else {
                                self.set("general_error", "Server neposlal result, kontaktuj organizátora.");
                            }
                            if ("result" in data && data.result !== "ok") {
                                if ("next" in data) {
                                    self.set("script_message_output", self.get("script_message_output") + "<br>" + "Další odevzdání možné " + _moment['default'].utc(data.next).local().format('LLL') + ".");
                                } else {
                                    self.set("script_message_output", self.get("script_message_output") + "<br>" + "Další odevzdání možné ihned.");
                                }
                            }
                            if ("message" in data && data.message.trim() !== "") {
                                self.set("script_message_output", data.message.trim());
                            }
                            if ("report" in data && data.report.trim() !== "") {
                                self.set("module.report_output", data.report.trim());
                            }
                            self.sendAction("submit_done");
                        },
                        error: function error() {
                            self.set("general_error", "Server odpověděl chybovým kódem, kontaktuj organizátora.");
                            self.sendAction("submit_done");
                        }
                    });
                });
            }
        }
    });
});