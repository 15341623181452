define("ksi/routes/settings", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            //return this.get("session.current_user");
            this.store.unloadAll("profile");
            return this.store.find("profile", "");
        },
        afterModel: function afterModel(model) {
            this.set("session.current", model);
        },
        title: "KSI: Nastavení",
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('global_error', null);
            controller.set('global_info', null);
        }
    });
});