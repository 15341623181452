define("ksi/routes/full-profile", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        session: _ember["default"].inject.service(),

        model: function model(params) {
            this.store.unloadAll("profile");
            return this.store.find("profile", params["profile_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("full_name");
        },
        renderTemplate: function renderTemplate() {
            this.render("profile", { controller: 'profile' });
        },
        title: function title(tokens) {
            return "KSI: profil – " + tokens.pop();
        },
        afterModel: function afterModel() {
            var self = this;
            return this.store.findAll("wave").then(function (result) {
                self.set("waves", result);
            });
        },
        setupController: function setupController(controller, model) {
            this.controllerFor('profile').setProperties({ content: model, fullProfile: true, waves: this.get('waves') });
        }
    });
});