define("ksi/routes/forum", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model() {
            this.store.unloadAll("thread");
            return this.store.findAll("thread", "");
        },
        title: "KSI: Diskuse",
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model', model);
            controller.set('info', null);
            controller.set('new_thread', false);
        }
    });
});