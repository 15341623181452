define("ksi/components/code-editor", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "div",
        elementId: "editor",
        classNames: ["code_editor"],
        didInsertElement: function didInsertElement() {
            this._super();
            _ember["default"].run.scheduleOnce('afterRender', this, function () {
                this.editor = window.ace.edit(this.get("id"));
                this.editor.setTheme("ace/theme/monokai");
                this.editor.getSession().setMode("ace/mode/python");
                this.editor.setAutoScrollEditorIntoView(true);
                this.editor.setOption("maxLines", 50);
            });
        }
    });
});