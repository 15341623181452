define("ksi/adapters/application", ["exports", "ember-data", "ember", "ember-simple-auth/mixins/data-adapter-mixin", "ksi/config/environment"], function (exports, _emberData, _ember, _emberSimpleAuthMixinsDataAdapterMixin, _ksiConfigEnvironment) {
    exports["default"] = _emberData["default"].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin["default"], {
        authorizer: 'authorizer:oauth2',
        host: _ksiConfigEnvironment["default"].API_LOC,

        headers: _ember["default"].computed('session.year', function () {
            var year = this.get('session.year');
            if (year) {
                return { 'year': year };
            } else {
                return {};
            }
        })
    });

    // Hack for singleton
    var inflector = _ember["default"].Inflector.inflector;
    inflector.uncountable('profile');
    inflector.uncountable('settings');
});