define('ksi/components/content-component', ['exports', 'ember', 'ksi/config/environment'], function (exports, _ember, _ksiConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        store: _ember['default'].inject.service(),
        session: _ember['default'].inject.service(),

        // content should be passed as argument to component
        model: null,

        // type could be 'list' or 'gallery'; 'list' is default
        type: null,

        // files to upload
        to_upload: [],

        // selected is absolute path
        selected: null,
        selectable: null,

        type_list: _ember['default'].computed("type", function () {
            return this.get("type") === 'list' || this.get("type") === null;
        }),
        type_gallery: _ember['default'].computed("type", function () {
            return this.get("type") === 'gallery';
        }),

        show_error: false,
        uploading: false,

        endpoint: _ember['default'].computed("model.id", function () {
            // endpoint must end with "/"
            return _ksiConfigEnvironment['default'].API_LOC + ("/content/" + this.get("model.id") + "/").replace("//", "/");
        }),

        upload_endpoint: _ember['default'].computed("model.id", function () {
            // endpoint must NOT end with "/"
            return ("/content/" + this.get("model.id")).replace("//", "/");
        }),

        files: _ember['default'].computed("model.files", "selected", function () {
            if (!this.get("model.files")) {
                return [];
            }
            var sel = this.get("selected");
            if (sel) {
                sel = sel.split("/").get("lastObject");
            }
            return this.get("model.files").map(function (file) {
                return _ember['default'].Object.create({
                    'file': file,
                    'selected': file === sel
                });
            });
        }),

        actions: {
            'select': function select(file) {
                var _new = this.get('endpoint') + file.get('file');
                this.set('selected', _new);
                this.sendAction("on_select", _new);
            },
            'file_list': function file_list(to_upload) {
                this.set("to_upload", to_upload);
                this.set("valid", !_ember['default'].isEmpty(to_upload));
            },
            'select_files': function select_files() {
                _ember['default'].$("#c-upload_" + this.get("model.id").replace("/", "\\/")).trigger('click');
            },
            'submit': function submit() {
                if (this.get("valid")) {
                    this.set("general_error", "");
                    this.set("progress_msg", "Nahrávám...");
                    this.set("uploading", true);
                    this.get("f_input").send("upload");
                } else {
                    this.set("general_error", "Nejsou vybrány žádné soubory!");
                    this.set("show_error", "");
                    this.set("progress_msg", "");
                }
            },
            'upload_finished': function upload_finished() {
                this.set("uploading", false);
                this.set("progress_msg", "");
                var self = this;
                this.get("store").find("content", this.get("model.id")).then(function (c) {
                    c.reload();
                    self.set("model", c);
                    self.set("to_upload", []);
                });
            },
            'upload_failed': function upload_failed(text, err) {
                this.set("uploading", false);
                this.set("progress_msg", "");
                this.set("general_error", text + ": " + err);
            },
            'progress': function progress(_progress) {
                this.set("progress_msg", "Nahrávám – " + Math.floor(_progress) + " %");
            },
            'delete_file': function delete_file(file) {
                var self = this;

                if (!confirm("Opravdu odstranit soubor " + file.get("file") + "?")) {
                    return;
                }

                file.set("deleting", true);

                this.get('session').authorize('authorizer:oauth2', function (header, h) {
                    _ember['default'].$.ajax({
                        url: self.get("endpoint") + file.get("file"),
                        type: 'DELETE',
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader(header, h);
                        },
                        success: function success() {
                            file.set("deleting", false);
                            self.get("store").find("content", self.get("model.id")).then(function (c) {
                                c.reload();
                                self.set("model", c);
                            });
                        },
                        error: function error() {
                            file.set("deleting", false);
                            alert("Chyba backendu, kontaktuj administrátora!");
                        }
                    });
                });
            },

            'copy-to-clipboard': function copyToClipboard(file) {
                window.prompt("Stiskni Ctrl+C, Enter", this.get("endpoint") + file.get("file"));
            }

        }
    });
});