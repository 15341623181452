define("ksi/controllers/registration", ["exports", "ember", "ksi/mixins/user-settings", "ksi/config/environment"], function (exports, _ember, _ksiMixinsUserSettings, _ksiConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend(_ksiMixinsUserSettings["default"], {
        registration_done: false,
        registration_in_progress: false,
        privacyPolicyAccepted: false,
        check: (function () {
            if (this.get("model.password") && this.get("model.password2") && this.get("model.password").length > 6 && this.get("model.password2").length > 6) {
                if (this.get("model.password") === this.get("model.password2")) {
                    this.set("password_error", undefined);
                } else {
                    this.set("password_error", true);
                }
            }
        }).observes("model.password", "model.password2"),
        getReferralString: function getReferralString() {
            var referralString = "{";
            var checkboxes = document.getElementsByClassName("referralCheckboxes");
            var customBox = document.getElementById("referral-other");

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = checkboxes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var singleCheckbox = _step.value;

                    console.log();
                    referralString += "\"" + singleCheckbox.id + "\":" + singleCheckbox.checked + ",";
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator["return"]) {
                        _iterator["return"]();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            try {
                referralString += "\"" + customBox.id + "\": \"" + btoa(encodeURIComponent(customBox.value)) + "\"";
            } catch (error) {
                referralString += "\"" + customBox.id + "\": \"" + btoa("JS ERROR: " + error.toString()) + "\"";
            }
            referralString += "}";
            return referralString;
        },
        actions: {
            register: function register() {
                var self = this;
                this.set("general_error", undefined);
                this.set("taken", false);
                this.set("privacyPolicyError", undefined);

                if (this.get("privacyPolicyAccepted") !== true) {
                    this.set("privacyPolicyError", true);
                    return;
                }

                if (this.get("model.password") !== this.get("model.password2")) {
                    this.set("password_error", true);

                    var elems = _ember["default"].$(".alert-danger");
                    var offset = Math.min.apply(null, _ember["default"].$.map(elems, function (elem) {
                        return _ember["default"].$(elem).offset().top;
                    }));
                    _ember["default"].$('html, body').animate({
                        scrollTop: offset - 150 //Magic constant
                    }, "slow");
                    return;
                }

                if (!this.get("model.short_info")) {
                    this.set("model.short_info", "");
                }

                this.set("model.referral", this.getReferralString());

                this.set("registration_in_progress", true);
                _ember["default"].$.ajax({
                    url: _ksiConfigEnvironment["default"].API_LOC + "/registration",
                    data: JSON.stringify(self.get("model")),
                    contentType: "application/json",
                    type: 'POST',
                    success: function success(data) {
                        self.set("registration_in_progress", false);
                        if (data && "error" in data) {
                            if (data.error === "duplicate_user") {
                                self.set("taken", true);
                            } else {
                                self.set("general_error", "Chyba při registraci! " + data.error);
                            }
                            var elems = _ember["default"].$(".alert-danger");
                            var offset = Math.min.apply(null, _ember["default"].$.map(elems, function (elem) {
                                return _ember["default"].$(elem).offset().top;
                            }));
                            _ember["default"].$('html, body').animate({
                                scrollTop: offset - 150 //Magic constant
                            }, "slow");
                        } else {
                            self.set("registration_done", true);
                        }
                    },
                    error: function error() {
                        self.set("registration_in_progress", false);
                        self.set("general_error", "Špatná odpověď ze serveru. Zkus to znovu za chvíli.");
                    }
                });
            },
            close_gdpr: function close_gdpr() {
                _ember["default"].$('#gdpr-modal').modal('hide');
            }
        }
    });
});