define("ksi/models/task-score", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        task: _emberData["default"].belongsTo("task"),
        achievements: _emberData["default"].hasMany("achievements", { async: true }),
        score: _emberData["default"].attr("number"),

        percent: _ember["default"].computed("score", function () {
            if (this.get("task").get("max_score") > 0) {
                return Math.floor(this.get("score") / this.get("task").get("max_score") * 100);
            } else {
                return 100;
            }
        }),

        score_published: _ember["default"].computed("score", function () {
            return this.get("score") != null;
        })
    });
});