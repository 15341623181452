define('ksi/models/evaluation', ['exports', 'ember-data', 'model-fragments'], function (exports, _emberData, _modelFragments) {
  exports['default'] = _modelFragments['default'].Fragment.extend({
    // MF does not support belongsTo relationship
    corrected_by: _emberData['default'].attr("string"), // id must ne string (because of x-select)
    full_report: _emberData['default'].attr("string"),
    last_modified: _emberData['default'].attr("date"),
    points: _emberData['default'].attr("number"),
    eval_id: _emberData['default'].attr("number"),

    general: _modelFragments['default'].fragment("generalfragment"),
    programming: _modelFragments['default'].fragment("programmingfragment"),
    quiz: _modelFragments['default'].fragment("quizfragment"),
    sortable: _modelFragments['default'].fragment("sortablefragment"),
    text: _modelFragments['default'].fragment("textfragment")
  });
});