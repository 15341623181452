define("ksi/models/registration", ["exports", "ember-data", "ksi/mixins/reset-scroll-unauthenticated"], function (exports, _emberData, _ksiMixinsResetScrollUnauthenticated) {
    exports["default"] = _emberData["default"].Model.extend(_ksiMixinsResetScrollUnauthenticated["default"], {
        first_name: _emberData["default"].attr("string"),
        last_name: _emberData["default"].attr("string"),
        nick_name: _emberData["default"].attr("string"),

        short_info: _emberData["default"].attr("string"),
        email: _emberData["default"].attr("string"),
        gender: _emberData["default"].attr("string"),

        password: _emberData["default"].attr("string"),
        password2: _emberData["default"].attr("string"),

        addr_street: _emberData["default"].attr("string"),
        addr_city: _emberData["default"].attr("string"),
        addr_zip: _emberData["default"].attr("string"),
        addr_country: _emberData["default"].attr("string"),

        school_name: _emberData["default"].attr("string"),
        school_street: _emberData["default"].attr("string"),
        school_city: _emberData["default"].attr("string"),
        school_zip: _emberData["default"].attr("string"),
        school_country: _emberData["default"].attr("string"),
        school_finish: _emberData["default"].attr("number"),

        tshirt_size: _emberData["default"].attr("string", { defaultValue: "NA" }),
        referral: _emberData["default"].attr("string", { defaultValue: "{}" })
    });
});