define('ksi/controllers/task/evaluation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service(),
        submitted: _ember['default'].computed('model.details.modules.[]', function () {
            var res = false;
            var modules = this.get('model.details.modules');
            if (!modules) {
                return false;
            }
            modules.mapBy('score.score').forEach(function (score) {
                res |= typeof score !== 'undefined';
            });
            return res;
        }),
        sum: _ember['default'].computed('model.details.modules.[]', function () {
            var sum = 0;
            this.get('model.details.modules').mapBy('score.score').forEach(function (score) {
                sum = sum + score;
            });
            return Math.floor(sum * 100) / 100;
        }),
        progress_width: (function () {
            var res = this.get("sum") / this.get("model.max_score") * 100;
            if (res > 100) {
                res = 100;
            }
            return new _ember['default'].Handlebars.SafeString('width: ' + Math.round(res) + '%');
        }).property("sum", "model.max_score")
    });
});