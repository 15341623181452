define("ksi/templates/components/correction-unit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 7
            },
            "end": {
              "line": 16,
              "column": 7
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("       ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n       ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "correction-module", [], ["orgs", ["subexpr", "@mut", [["get", "orgs", ["loc", [null, [14, 35], [14, 39]]]]], [], []], "module", ["subexpr", "@mut", [["get", "module", ["loc", [null, [14, 47], [14, 53]]]]], [], []], "dirty", "dirty"], ["loc", [null, [14, 10], [14, 69]]]]],
        locals: ["module"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 7
            },
            "end": {
              "line": 22,
              "column": 7
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("           ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n               ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary");
          var el3 = dom.createTextNode("Uložit body");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n           ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element9);
          return morphs;
        },
        statements: [["element", "action", ["save"], [], ["loc", [null, [20, 23], [20, 40]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 15
            },
            "end": {
              "line": 32,
              "column": 15
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "float: left; margin-left: 10px;");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "javascript.void()");
          var el3 = dom.createTextNode("Odebrat");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                   ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element7, 1, 1);
          morphs[1] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [["inline", "achievement-small", [], ["model", ["subexpr", "@mut", [["get", "ach", ["loc", [null, [29, 48], [29, 51]]]]], [], []]], ["loc", [null, [29, 22], [29, 53]]]], ["element", "action", ["ach_del", ["get", "ach.id", ["loc", [null, [30, 69], [30, 75]]]]], [], ["loc", [null, [30, 50], [30, 77]]]]],
        locals: ["ach"],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 15
            },
            "end": {
              "line": 34,
              "column": 15
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    –\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 22
              },
              "end": {
                "line": 43,
                "column": 22
              }
            },
            "moduleName": "ksi/templates/components/correction-unit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'value');
            morphs[1] = dom.createUnsafeMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "ach.id", ["loc", [null, [42, 41], [42, 47]]]]]]], ["content", "ach.title", ["loc", [null, [42, 51], [42, 66]]]]],
          locals: ["ach"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 12
            },
            "end": {
              "line": 51,
              "column": 12
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-4");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("select");
          dom.setAttribute(el2, "class", "form-control");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("option");
          dom.setAttribute(el3, "value", "");
          var el4 = dom.createTextNode("Vyber trofej pro přidání");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-2");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary");
          var el3 = dom.createTextNode("Přidat trofej");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1, 1]);
          var element6 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element5, 'id');
          morphs[1] = dom.createMorphAt(element5, 3, 3);
          morphs[2] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [["attribute", "id", ["concat", ["a_", ["get", "ident", ["loc", [null, [39, 36], [39, 41]]]]]]], ["block", "each", [["get", "achievements", ["loc", [null, [41, 30], [41, 42]]]]], [], 0, null, ["loc", [null, [41, 22], [43, 31]]]], ["element", "action", ["add_achievement"], [], ["loc", [null, [48, 28], [48, 56]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 9
            },
            "end": {
              "line": 58,
              "column": 9
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "thread-view", [], ["thread", ["subexpr", "@mut", [["get", "model.comment", ["loc", [null, [57, 31], [57, 44]]]]], [], []], "mark_as_read", false], ["loc", [null, [57, 10], [57, 65]]]]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 20
                },
                "end": {
                  "line": 64,
                  "column": 20
                }
              },
              "moduleName": "ksi/templates/components/correction-unit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      Zrušit komentář\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 20
                },
                "end": {
                  "line": 66,
                  "column": 20
                }
              },
              "moduleName": "ksi/templates/components/correction-unit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      Nový komentář\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 12
              },
              "end": {
                "line": 69,
                "column": 12
              }
            },
            "moduleName": "ksi/templates/components/correction-unit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn btn-primary");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["new_thread"], [], ["loc", [null, [61, 50], [61, 73]]]], ["block", "if", [["get", "new_thread", ["loc", [null, [62, 26], [62, 36]]]]], [], 0, 1, ["loc", [null, [62, 20], [66, 27]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 12
              },
              "end": {
                "line": 73,
                "column": 12
              }
            },
            "moduleName": "ksi/templates/components/correction-unit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-info");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "info", ["loc", [null, [72, 44], [72, 52]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 12
              },
              "end": {
                "line": 86,
                "column": 12
              }
            },
            "moduleName": "ksi/templates/components/correction-unit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "margin-top:20px; margin-bottom:20px;");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("form");
            dom.setAttribute(el2, "class", "form-horizontal");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "controls form-inline");
            dom.setAttribute(el3, "style", "margin-top:20px");
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4, "for", "threadName");
            var el5 = dom.createTextNode("Komentář:");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "btn btn-primary");
            dom.setAttribute(el3, "style", "margin-top:20px");
            var el4 = dom.createTextNode("Odeslat");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createMorphAt(element1, 5, 5);
            morphs[2] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["inline", "ale-rt", [], ["type", "danger", "closeBtn", true, "text", ["subexpr", "@mut", [["get", "thread_content_error", ["loc", [null, [79, 68], [79, 88]]]]], [], []]], ["loc", [null, [79, 26], [79, 90]]]], ["inline", "text-editor", [], ["value", ["subexpr", "@mut", [["get", "thread_content", ["loc", [null, [81, 46], [81, 60]]]]], [], []]], ["loc", [null, [81, 26], [81, 62]]]], ["element", "action", ["post_thread"], [], ["loc", [null, [83, 78], [83, 102]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 9
            },
            "end": {
              "line": 87,
              "column": 9
            }
          },
          "moduleName": "ksi/templates/components/correction-unit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.year_editable", ["loc", [null, [59, 18], [59, 39]]]]], [], 0, null, ["loc", [null, [59, 12], [69, 19]]]], ["block", "if", [["get", "info", ["loc", [null, [71, 18], [71, 22]]]]], [], 1, null, ["loc", [null, [71, 12], [73, 19]]]], ["block", "if", [["get", "new_thread", ["loc", [null, [75, 18], [75, 28]]]]], [], 2, null, ["loc", [null, [75, 12], [86, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 91,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/components/correction-unit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "style", "hight:400px");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "float: right; margin-right: 50px;");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "float: right; margin-left: 50px; margin-right: 50px;");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-body");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n       ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form-group row");
        var el4 = dom.createTextNode("\n           ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-6");
        var el5 = dom.createTextNode("\n               ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "style", "float: left;");
        var el6 = dom.createElement("label");
        var el7 = dom.createTextNode("Uživatelovy trofeje:");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("           ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n       ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n         ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Komentář:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("       ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var element13 = dom.childAt(element12, [5]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element10, 'class');
        morphs[1] = dom.createAttrMorph(element11, 'class');
        morphs[2] = dom.createMorphAt(element11, 1, 1);
        morphs[3] = dom.createUnsafeMorphAt(dom.childAt(element11, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element11, [5]), 1, 1);
        morphs[5] = dom.createMorphAt(element12, 1, 1);
        morphs[6] = dom.createMorphAt(element12, 3, 3);
        morphs[7] = dom.createMorphAt(dom.childAt(element13, [1]), 3, 3);
        morphs[8] = dom.createMorphAt(element13, 3, 3);
        morphs[9] = dom.createMorphAt(dom.childAt(element12, [7]), 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["panel panel-ksi ", ["subexpr", "if", [["get", "model.corrected", ["loc", [null, [1, 33], [1, 48]]]], "panel-module-correct"], [], ["loc", [null, [1, 28], [1, 73]]]]]]], ["attribute", "class", ["concat", ["panel-heading panel-heading-ksi ", ["subexpr", "if", [["get", "model.corrected", ["loc", [null, [2, 55], [2, 70]]]], "panel-heading-module-correct"], [], ["loc", [null, [2, 50], [2, 103]]]]]]], ["content", "model.user.full_name", ["loc", [null, [3, 6], [3, 30]]]], ["content", "model.task_id.title", ["loc", [null, [5, 8], [5, 33]]]], ["content", "statemsg", ["loc", [null, [8, 8], [8, 20]]]], ["block", "each", [["get", "model.modules", ["loc", [null, [12, 15], [12, 28]]]]], [], 0, null, ["loc", [null, [12, 7], [16, 16]]]], ["block", "if", [["get", "session.year_editable", ["loc", [null, [18, 13], [18, 34]]]]], [], 1, null, ["loc", [null, [18, 7], [22, 14]]]], ["block", "each", [["get", "model.achievements", ["loc", [null, [27, 23], [27, 41]]]]], [], 2, 3, ["loc", [null, [27, 15], [34, 24]]]], ["block", "if", [["get", "session.year_editable", ["loc", [null, [37, 18], [37, 39]]]]], [], 4, null, ["loc", [null, [37, 12], [51, 19]]]], ["block", "if", [["get", "model.comment", ["loc", [null, [56, 15], [56, 28]]]]], [], 5, 6, ["loc", [null, [56, 9], [87, 16]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});