define("ksi/routes/admin/year-create", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            return _ember["default"].RSVP.hash({
                years: this.store.findAll("year"),
                orgs: this.store.query("user", { filter: "orgs-all" })
            });
        },
        title: "KSI: Nový ročník"
    });
});