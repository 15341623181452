define("ksi/routes/application", ["exports", "ember", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
    exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin["default"], {
        session: _ember["default"].inject.service(),

        title: "KSI – Korespondenční seminář z informatiky",

        actions: {
            reload: function reload() {
                this.refresh();
            }
        }

    });
});