define('ksi/components/task-feedback', ['exports', 'ember', 'ksi/config/environment'], function (exports, _ember, _ksiConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        session: _ember['default'].inject.service(),
        store: _ember['default'].inject.service(),

        show: true,
        stars_already_set: false,
        feedback_button_text: "Skrýt feedback",

        didRender: function didRender() {
            this._super();
            var self = this;
            var $ = _ember['default'].$;
            /*
            if (self.get("model.feedbacks.filled") === false){ // doesn't work, don't know why. setting show to True as default
                self.set("show", true);
                this.send("set_feedback_button_text");
            }
            */
            if (_ember['default'].$("[id^=task-feedback-answer-]").length === 0) {
                return;
            }
            if (self.get("stars_already_set")) {
                return;
            }
            self.set("stars_already_set", true);
            self.displayStars();
            var starsFields = $(".task-feedback-stars-field");
            for (var i = 0; i < starsFields.length; i++) {
                var firstStar = $(starsFields[i]).siblings("ul").first().find("li").first();
                var val = $(starsFields[i]).val();
                if (val !== "") {
                    self.highlightStars(firstStar, val);
                }
            }
        },

        // trouble with sliders
        // https://github.com/seiyria/angular-bootstrap-slider/issues/57
        // https://github.com/seiyria/bootstrap-slider/issues/250

        highlightStars: function highlightStars(singleStar, val) {
            var $ = _ember['default'].$;
            var stars = $(singleStar).parent().children('li.star');

            for (var i = 0; i < stars.length; i++) {
                $(stars[i]).removeClass('star-selected');
            }

            for (var i = 0; i < val; i++) {
                $(stars[i]).addClass('star-selected');
            }

            var ratingValue = parseInt(stars.filter('li.star-selected').last().data('value'), 10);
            $(singleStar).parent().siblings("input").val(ratingValue);
        },

        displayStars: function displayStars() {
            // https://codepen.io/depy/pen/vEWWdw?editors=1000
            var $ = _ember['default'].$;
            var self = this;
            /* 1. Visualizing things on Hover - See next part for action on click */
            $('.feedback-stars-list li').on('mouseover', function () {
                var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

                // Now highlight all the stars that's not after the current hovered star
                $(this).parent().children('li.star').each(function (e) {
                    if (e < onStar) {
                        $(this).addClass('hover');
                    } else {
                        $(this).removeClass('hover');
                    }
                });
            }).on('mouseout', function () {
                $(this).parent().children('li.star').each(function () {
                    $(this).removeClass('hover');
                });
            });

            /* 2. Action to perform on click */
            $('.feedback-stars-list li').on('click', function () {
                var onStar = parseInt($(this).data('value'), 10); // The star currently selected

                self.highlightStars($(this), onStar);
            });
        },

        save: function save() {
            var self = this;
            this.set("statemsg", "Ukládám");
            var data_obj = JSON.stringify(this.get("model.feedbacks"), null, 2);
            var data_json = JSON.parse(data_obj);
            var categories = data_json["content"]["categories"];
            for (var i = 0; i < categories.length; i++) {
                var element_name = data_json["content"]["categories"][i]["id"];
                var answer = _ember['default'].$("#task-feedback-answer-" + element_name).val();
                var ftype = data_json["content"]["categories"][i]["ftype"];
                if (ftype === "stars" || ftype === "line") {
                    if (answer === "") {
                        answer = 0;
                    } else {
                        answer = parseInt(answer);
                    }
                }
                data_json["content"]["categories"][i]["answer"] = answer;
            }
            data_json["content"]["filled"] = true;
            data_json["content"]["id"] = self.get("model").id;

            data_json["feedback"] = data_json["content"];
            delete data_json["isFulfilled"];
            delete data_json["content"];
            var data_json_string = JSON.stringify(data_json, null, 2);

            this.get('session').authorize('authorizer:oauth2', function (header, h) {
                _ember['default'].$.ajax({
                    url: _ksiConfigEnvironment['default'].API_LOC + "/feedbacks/" + self.get("model").id,
                    data: data_json_string,
                    contentType: "application/json",
                    type: 'PUT',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader(header, h);
                    },
                    success: function success() {
                        self.set("save_status", "Díky!");
                    },
                    error: function error(resp) {
                        var e = "Tvůj feedback se bohužel nepodařilo odeslat.<br>" + resp.message;
                        if (resp.errors && resp.errors[0]) {
                            e += "<br>" + resp.errors[0].status + " : " + resp.errors[0].title;
                        }
                        self.set("feedback_error", e);
                    }
                });
            });
        },

        statemsg: "Uloženo",
        actions: {
            saveTaskFeedback: function saveTaskFeedback() {
                this.save();
            },
            sliderChanged: function sliderChanged(value, options) {
                _ember['default'].$(options.evt.currentTarget).parent().siblings("input").val(value);
            },
            show: function show() {
                this.set("show", !this.get("show"));
                this.send("set_feedback_button_text");
            },
            set_feedback_button_text: function set_feedback_button_text() {
                if (this.get("show")) {
                    this.set("feedback_button_text", "Skrýt feedback");
                } else {
                    this.set("feedback_button_text", "Zobrazit feedback");
                }
            }

        }
    });
});