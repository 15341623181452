define("ksi/models/achievement", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        active: _emberData["default"].attr("boolean"),
        title: _emberData["default"].attr("string"),
        picture: _emberData["default"].attr("string"),
        description: _emberData["default"].attr("string"),
        persistent: _emberData["default"].attr("boolean"),
        year: _emberData["default"].belongsTo("year", { async: true }),

        shortTitle: _ember["default"].computed("title", function () {
            if (this.get("title")) {
                return this.get("title").substring(0, 3) + ".";
            } else {
                return "";
            }
        })
    });
});