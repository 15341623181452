define("ksi/models/corrections-info", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        title: _emberData["default"].attr("string"),
        wave: _emberData["default"].belongsTo("wave"),
        author: _emberData["default"].belongsTo("user", { async: true }),
        corr_state: _emberData["default"].attr("string"),
        solvers: _emberData["default"].hasMany("user"),

        base: _ember["default"].computed("corr_state", function () {
            return this.get("corr_state") === "base";
        }),
        working: _ember["default"].computed("corr_state", function () {
            return this.get("corr_state") === "working";
        }),
        corrected: _ember["default"].computed("corr_state", function () {
            return this.get("corr_state") === "corrected";
        }),
        published: _ember["default"].computed("corr_state", function () {
            return this.get("corr_state") === "published";
        })
    });
});