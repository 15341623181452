define("ksi/models/year", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        index: _emberData["default"].attr("number"),
        year: _emberData["default"].attr("string"),
        sum_points: _emberData["default"].attr("number"),
        tasks_cnt: _emberData["default"].attr("number"),
        sealed: _emberData["default"].attr("boolean"),
        point_pad: _emberData["default"].attr("number"),
        active_orgs: _emberData["default"].hasMany("user")
    });
});