define("ksi/routes/admin/execs", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        titleToken: function titleToken() {
            return "Spuštění kódu";
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.set("execs", []);
            }
        }
    });
});