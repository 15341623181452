define("ksi/controllers/admin/article-create", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),
        saving: false,

        time_published: new Date(),

        actions: {
            'article-create': function articleCreate() {
                var self = this;

                this.set("saving", true);

                var pict;
                if (this.get("picture") === "") {
                    pict = null;
                } else {
                    pict = this.get("picture");
                }

                this.get("store").createRecord('article', {
                    title: this.get("title"),
                    time_published: this.get("time_published"),
                    picture: null,
                    body: this.get("body"),
                    published: this.get("published"),
                    resource: null
                }).save().then(function () {
                    self.set("saving", false);
                    self.transitionToRoute('admin/articles');
                }, function () {
                    self.set("saving", false);
                    self.set("error_status", "Špatná odpověď ze serveru! Zkus to za chvíli znovu. Pokud problém přetrvává, kontaktuj organizátora.");
                });
            }
        }

    });
});