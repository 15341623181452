define("ksi/routes/admin/wave-create", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            return _ember["default"].RSVP.hash({
                users: this.store.query("user", { filter: "organisators" }),
                waves: this.store.findAll("wave")
            });
        },
        title: "KSI: Nová vlna"
    });
});