define("ksi/models/post", ["exports", "ember-data"], function (exports, _emberData) {

  /**
   * Single post in discussion
   */
  exports["default"] = _emberData["default"].Model.extend({
    author: _emberData["default"].belongsTo("user", { async: true }),
    thread: _emberData["default"].belongsTo("thread", { async: true, inverse: null }),
    published_at: _emberData["default"].attr("date"),
    body: _emberData["default"].attr("string"),
    parent: _emberData["default"].belongsTo("post", { async: true, inverse: "reaction" }),
    reaction: _emberData["default"].hasMany("post", { async: true, inverse: "parent" }),
    is_new: _emberData["default"].attr("boolean"),
    temporary: _emberData["default"].attr("boolean") // temporary threads are invisible
    // this is used when creating threads
  });
});