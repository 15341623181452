define("ksi/routes/admin/task-edit", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
  exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
    model: function model(params) {
      return this.store.find("atask", params["task_id"]);
    },
    title: "KSI: Správa úloh",
    actions: {
      willTransition: function willTransition() {
        this.controller.set('save_status', "");
        this.controller.set('error_status', "");
      }
    }
  });
});