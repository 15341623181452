define("ksi/components/module-wrap", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "",
        classNames: [],
        is_editing: false,
        submitting: false,
        show_report: false,
        report_button_text: "Zobrazit orgovský report",

        session: _ember["default"].inject.service(),
        show: (function () {
            return this.get("is_editing") || !this.get("module.is_correct") || this.get("module.is_general");
        }).property("is_editing", "module.state"),

        didRender: function didRender() {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
        },

        actions: {
            submit: function submit() {
                this.set("submitting", true);
                this.get("module_logic").send("submit");
            },
            show: function show() {
                this.set("is_editing", true);
            },
            submit_done: function submit_done() {
                this.send("set_report_button_text");
                this.set("submitting", false);
            },
            submit_succ_done: function submit_succ_done() {
                this.set("submitting", false);
                if (this.getWithDefault("module.blockClosing", false) === false) {
                    this.set("is_editing", false);
                }
                this.sendAction("submit");
            },
            set_report_button_text: function set_report_button_text() {
                if (this.get("module.show_report")) {
                    this.set("report_button_text", "Skrýt orgovský report");
                } else {
                    this.set("report_button_text", "Zobrazit orgovský report");
                }
            },
            toggle_report: function toggle_report() {
                this.set("module.show_report", !this.get("module.show_report"));
                this.send("set_report_button_text");
            }
        }
    });
});