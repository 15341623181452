define('ksi/adapters/content', ['exports', 'ember-data', 'ksi/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _ksiConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {
    exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
        authorizer: 'authorizer:oauth2',
        host: _ksiConfigEnvironment['default'].API_LOC,

        pathForType: function pathForType(modelName) {
            // do not change content to contents
            return modelName;
        },

        buildURL: function buildURL(record, suffix) {
            // do not replace shashes in id to %2F
            return this._super(record, suffix).replace("%2F", "/");
        }
    });
});