define("ksi/components/file-input", ["exports", "ember", "ember-uploader", "ember-component-inbound-actions/inbound-actions", "ksi/config/environment"], function (exports, _ember, _emberUploader, _emberComponentInboundActionsInboundActions, _ksiConfigEnvironment) {

    _emberUploader["default"].Uploader.reopen({
        session: _ember["default"].inject.service(),
        ajax: function ajax(url, param, method) {
            var self = this;
            var tmp = this.ajaxSettings(url, param, method);
            tmp['beforeSend'] = function (xhr) {
                xhr.setRequestHeader(self.get('header'), self.get('content'));
            };
            return this._ajax(tmp);
        }
    });

    exports["default"] = _emberUploader["default"].FileField.extend(_emberComponentInboundActionsInboundActions["default"], {
        session: _ember["default"].inject.service(),
        url: _ember["default"].computed("endpoint", function () {
            return _ksiConfigEnvironment["default"]["API_LOC"] + this.get("endpoint");
        }),
        classNames: ["hide"],
        attributeBindings: ["multiple", "accept"],
        limit: 20971520,
        filesDidChange: function filesDidChange(files) {
            var self = this;
            var res = [];
            var size = 0;
            for (var i = 0; i !== files.length; i++) {
                var file = files.item(i).name;
                res.push(file);
                size += files[i].size;
            }

            this.set("size", size);
            if (this.get("size") > this.get("limit")) {
                this.sendAction("upload_failed", "Nelze nahrát soubory větší než 20 MB", "");
            }

            this.sendAction("file_list", res);
            this.get('session').authorize('authorizer:oauth2', function (header, content) {
                self.set('header', header);
                self.set('content', content);
            });
        },
        actions: {
            upload: function upload() {
                if (this.get("size") > this.get("limit")) {
                    this.sendAction("upload_failed", "Nelze nahrát soubory větší než 20 MB", "");
                }
                var self = this;
                this.get('session').authorize('authorizer:oauth2', function (h, c) {
                    var uploader = _emberUploader["default"].Uploader.create({
                        url: self.get("url"),
                        header: h,
                        content: c
                    });

                    uploader.on("didUpload", function () {
                        self.sendAction("upload_finished");
                    });

                    uploader.on("didError", function (jqXHR, textStatus, errorThrown) {
                        //self.sendAction("upload_failed", JSON.parse(jqXHR.responseText)["error"], errorThrown);
                        self.sendAction("upload_failed", "Nepodařilo se nahrát soubory – chyba spojení nebo příliš velké soubory", errorThrown);
                    });

                    uploader.on("progress", function (e) {
                        self.sendAction("progress", e.percent);
                    });

                    if (!_ember["default"].isEmpty(self.get("files"))) {
                        uploader.upload(self.get("files"));
                    }
                });
            }

        }
    });
});