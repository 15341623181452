define("ksi/routes/admin/article-edit", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model(params) {
            return this.store.find("article", params["article_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("title");
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        }
    });
});