define("ksi/controllers/settings", ["exports", "ember", "ksi/mixins/user-settings"], function (exports, _ember, _ksiMixinsUserSettings) {
    exports["default"] = _ember["default"].Controller.extend(_ksiMixinsUserSettings["default"], {
        global_error: undefined,
        global_info: undefined,
        actions: {
            upload_pic: function upload_pic() {
                _ember["default"].$("#picture_input").trigger('click');
            },
            upload_finished: function upload_finished() {
                // reload image from backend
                var self = this;
                this.store.find("profile", "").then(function (profile) {
                    self.set("model.profile_picture", profile.get("profile_picture"));
                    var d = new Date();
                    _ember["default"].$("#profile-image").attr("src", profile.get("profile_picture_r") + "?" + d.getTime());
                    alert("Obrázek změněn!");
                });
            },
            upload_failed: function upload_failed(status, error) {
                alert("Nepodařilo se nahrát obrázek: " + status + " " + error);
            }
        }
    });
});