define('ksi/components/header-admin', ['exports', 'ember', 'ksi/config/environment'], function (exports, _ember, _ksiConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        session: _ember['default'].inject.service(),
        didInsertElement: function didInsertElement() {
            this._super();
            var self = this;

            this.get('session').authorize('authorizer:oauth2', function (header, content) {
                var request = new XMLHttpRequest();
                request.open("GET", _ksiConfigEnvironment['default'].API_LOC + "/admin/monitoring-dashboard", true);
                request.setRequestHeader(header, content);

                request.onload = function () {
                    if (this.status === 200) {
                        _ember['default'].$("#link-to-monitoring-dashboard").attr("href", JSON.parse(this.response)["url"]);
                    } else {
                        console.warn("Nepovedlo se načíst tajný odkaz na monitorovací nástěnku.");
                    }
                };
                request.send();
            });
        }
    });
});