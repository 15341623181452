define("ksi/templates/profile", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 5
            },
            "end": {
              "line": 9,
              "column": 5
            }
          },
          "moduleName": "ksi/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment(" Sem se muze dostat pri neuspesne refreshi tokenu ");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "margin-top:30px");
          var el2 = dom.createTextNode("\n             ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n         ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ale-rt", [], ["type", "danger", "text", "Uživatel není přihlášen, proveďte odhlášení a opětovné přihlášení!"], ["loc", [null, [7, 13], [7, 111]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 20
                },
                "end": {
                  "line": 19,
                  "column": 70
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Veřejný profil");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 16
              },
              "end": {
                "line": 20,
                "column": 16
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["user-profile", ["get", "model.id", ["loc", [null, [19, 46], [19, 54]]]]], [], 0, null, ["loc", [null, [19, 20], [19, 82]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 24
                  },
                  "end": {
                    "line": 22,
                    "column": 72
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Úplný profil");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 20
                },
                "end": {
                  "line": 23,
                  "column": 20
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", ["full-profile", ["get", "model.id", ["loc", [null, [22, 50], [22, 58]]]]], [], 0, null, ["loc", [null, [22, 24], [22, 84]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 16
              },
              "end": {
                "line": 24,
                "column": 16
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "is_current_user_org", ["loc", [null, [21, 26], [21, 45]]]]], [], 0, null, ["loc", [null, [21, 20], [23, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 24
                },
                "end": {
                  "line": 36,
                  "column": 24
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h6");
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "glyphicon glyphicon-envelope");
              dom.setAttribute(el2, "aria-hidden", "true");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'href');
              morphs[1] = dom.createMorphAt(element8, 0, 0);
              return morphs;
            },
            statements: [["attribute", "href", ["concat", ["mailto:", ["get", "model.email", ["loc", [null, [34, 120], [34, 131]]]]]]], ["content", "model.email", ["loc", [null, [34, 135], [34, 150]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 12
              },
              "end": {
                "line": 42,
                "column": 12
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "panel panel-profile");
            dom.setAttribute(el1, "style", "margin-top:30px");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "panel-body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h6");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "glyphicon glyphicon-education");
            dom.setAttribute(el4, "aria-hidden", "true");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h6");
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "glyphicon glyphicon-map-marker");
            dom.setAttribute(el4, "aria-hidden", "true");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element9, 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 2, 2);
            morphs[2] = dom.createMorphAt(dom.childAt(element9, [7]), 2, 2);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.email", ["loc", [null, [33, 30], [33, 41]]]]], [], 0, null, ["loc", [null, [33, 24], [36, 31]]]], ["content", "model.school_name", ["loc", [null, [37, 99], [37, 120]]]], ["content", "country", ["loc", [null, [39, 100], [39, 111]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 24
                  },
                  "end": {
                    "line": 54,
                    "column": 108
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "achievement-small", [], ["model", ["subexpr", "@mut", [["get", "achievement", ["loc", [null, [54, 95], [54, 106]]]]], [], []]], ["loc", [null, [54, 69], [54, 108]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 20
                },
                "end": {
                  "line": 55,
                  "column": 20
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", ["achievements"], ["class", "noContent"], 0, null, ["loc", [null, [54, 24], [54, 120]]]]],
            locals: ["achievement"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 28
                  },
                  "end": {
                    "line": 79,
                    "column": 28
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("., \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "year.id", ["loc", [null, [78, 32], [78, 43]]]]],
              locals: ["year"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 16
                },
                "end": {
                  "line": 82,
                  "column": 16
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Skóre: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Percentil: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" %");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Ročníky: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.score", ["loc", [null, [68, 89], [68, 104]]]], ["content", "model.percentile", ["loc", [null, [72, 89], [72, 109]]]], ["block", "each", [["get", "model.seasons", ["loc", [null, [77, 36], [77, 49]]]]], [], 0, null, ["loc", [null, [77, 28], [79, 37]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 94,
                    "column": 29
                  },
                  "end": {
                    "line": 96,
                    "column": 28
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(". \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "year.id", ["loc", [null, [95, 32], [95, 43]]]]],
              locals: ["year"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 16
                },
                "end": {
                  "line": 99,
                  "column": 16
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Skóre: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Vyřešených úloh: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-xs-4");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points-text");
              dom.setAttribute(el2, "class", "inline");
              var el3 = dom.createTextNode("Ročníky: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "id", "profile-points");
              dom.setAttribute(el2, "class", "inline");
              dom.setAttribute(el2, "style", "margin-left:10px");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 1, 1);
              return morphs;
            },
            statements: [["content", "model.score", ["loc", [null, [85, 89], [85, 104]]]], ["content", "model.tasks_num", ["loc", [null, [89, 89], [89, 108]]]], ["block", "each", [["get", "model.seasons", ["loc", [null, [94, 37], [94, 50]]]]], [], 0, null, ["loc", [null, [94, 29], [96, 37]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 12
                },
                "end": {
                  "line": 118,
                  "column": 12
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "id", "profile-points-text");
              var el2 = dom.createTextNode("\n                    Skóre:\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "visible-print-inline");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "style", "font-weight: bold;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" %");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "style", "font-style: italic;");
              var el3 = dom.createTextNode("(úspěšný řešitel ⇔ skóre ≥ 60 %)");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "progress hidden-print");
              dom.setAttribute(el1, "style", "margin-top:10px");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "progress-bar progress-bar-warning progress-bar-ksi");
              dom.setAttribute(el2, "role", "progressbar");
              dom.setAttribute(el2, "aria-valuenow", "60");
              dom.setAttribute(el2, "aria-valuemin", "0");
              dom.setAttribute(el2, "aria-valuemax", "100");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" %\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "progress-bar-ksi score_60_limit");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
              morphs[1] = dom.createAttrMorph(element7, 'style');
              morphs[2] = dom.createMorphAt(element7, 1, 1);
              return morphs;
            },
            statements: [["content", "model.percent", ["loc", [null, [108, 57], [108, 74]]]], ["attribute", "style", ["concat", ["width: ", ["get", "model.percent", ["loc", [null, [113, 176], [113, 189]]]], "%"]]], ["content", "model.percent", ["loc", [null, [114, 24], [114, 41]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 119,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "margin-top:30px");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h5");
            var el3 = dom.createTextNode("Trofeje");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("hr");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "panel panel-achievements");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "panel-body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "margin-top:30px");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h5");
            var el3 = dom.createTextNode("Statistiky");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("hr");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]), 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.achievements", ["loc", [null, [53, 28], [53, 46]]]]], [], 0, null, ["loc", [null, [53, 20], [55, 29]]]], ["block", "if", [["get", "model.percentile", ["loc", [null, [65, 22], [65, 38]]]]], [], 1, 2, ["loc", [null, [65, 16], [99, 23]]]], ["block", "if", [["get", "model.percent", ["loc", [null, [104, 18], [104, 31]]]]], [], 3, null, ["loc", [null, [104, 12], [118, 19]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 155,
                        "column": 87
                      },
                      "end": {
                        "line": 155,
                        "column": 141
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createUnsafeMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "result.task.title", ["loc", [null, [155, 118], [155, 141]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 159,
                          "column": 44
                        },
                        "end": {
                          "line": 159,
                          "column": 110
                        }
                      },
                      "moduleName": "ksi/templates/profile.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "achievement-small", [], ["model", ["subexpr", "@mut", [["get", "achievement", ["loc", [null, [159, 97], [159, 108]]]]], [], []]], ["loc", [null, [159, 71], [159, 110]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 158,
                        "column": 40
                      },
                      "end": {
                        "line": 160,
                        "column": 40
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["achievements"], [], 0, null, ["loc", [null, [159, 44], [159, 122]]]]],
                  locals: ["achievement"],
                  templates: [child0]
                };
              })();
              var child2 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 163,
                        "column": 40
                      },
                      "end": {
                        "line": 165,
                        "column": 40
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("h5");
                    dom.setAttribute(el1, "style", "margin-left: 10px");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" / ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element0, 0, 0);
                    morphs[1] = dom.createMorphAt(element0, 2, 2);
                    return morphs;
                  },
                  statements: [["content", "result.score", ["loc", [null, [164, 74], [164, 90]]]], ["content", "result.task.max_score", ["loc", [null, [164, 93], [164, 118]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child3 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 165,
                        "column": 40
                      },
                      "end": {
                        "line": 167,
                        "column": 40
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("h5");
                    dom.setAttribute(el1, "style", "margin-left: 10px");
                    var el2 = dom.createTextNode("–");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child4 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 170,
                        "column": 40
                      },
                      "end": {
                        "line": 172,
                        "column": 40
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("h5");
                    dom.setAttribute(el1, "style", "margin-left: 10px");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" %");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "result.percent", ["loc", [null, [171, 74], [171, 92]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child5 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 172,
                        "column": 40
                      },
                      "end": {
                        "line": 174,
                        "column": 40
                      }
                    },
                    "moduleName": "ksi/templates/profile.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("h5");
                    dom.setAttribute(el1, "style", "margin-left: 10px");
                    var el2 = dom.createTextNode("–");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 149,
                      "column": 20
                    },
                    "end": {
                      "line": 179,
                      "column": 20
                    }
                  },
                  "moduleName": "ksi/templates/profile.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "panel panel-profile-assignment");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "panel-body");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "row");
                  var el4 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "col-xs-6");
                  var el5 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("img");
                  dom.setAttribute(el5, "class", "inline");
                  dom.setAttribute(el5, "class", "profile-picture-task");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  dom.setAttribute(el5, "class", "inline");
                  dom.setAttribute(el5, "style", "margin-left:10px");
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "col-xs-2");
                  var el5 = dom.createTextNode("\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "col-xs-2");
                  var el5 = dom.createTextNode("\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "col-xs-2");
                  var el5 = dom.createTextNode("\n");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("                                    ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                                ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1, 1, 1]);
                  var element2 = dom.childAt(element1, [1]);
                  var element3 = dom.childAt(element2, [1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createAttrMorph(element3, 'src');
                  morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element1, [7]), 1, 1);
                  return morphs;
                },
                statements: [["attribute", "src", ["concat", [["get", "result.task.picture", ["loc", [null, [154, 67], [154, 86]]]]]]], ["block", "link-to", ["task", ["get", "result.task", ["loc", [null, [155, 105], [155, 116]]]]], [], 0, null, ["loc", [null, [155, 87], [155, 153]]]], ["block", "each", [["get", "result.achievements", ["loc", [null, [158, 48], [158, 67]]]]], [], 1, null, ["loc", [null, [158, 40], [160, 49]]]], ["block", "if", [["get", "result.score_published", ["loc", [null, [163, 46], [163, 68]]]]], [], 2, 3, ["loc", [null, [163, 40], [167, 47]]]], ["block", "if", [["get", "result.score_published", ["loc", [null, [170, 46], [170, 68]]]]], [], 4, 5, ["loc", [null, [170, 40], [174, 47]]]]],
                locals: ["result"],
                templates: [child0, child1, child2, child3, child4, child5]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 126,
                    "column": 17
                  },
                  "end": {
                    "line": 192,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h5");
                var el2 = dom.createTextNode("Odevzdané úlohy – ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "panel");
                dom.setAttribute(el1, "style", "box-shadow: none; margin-bottom: -10px;");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "panel-body");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "row");
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "col-xs-1");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "col-xs-5");
                var el5 = dom.createTextNode("\n                                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("h5");
                var el6 = dom.createTextNode("Název");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "col-xs-2");
                var el5 = dom.createTextNode("\n                                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("h5");
                var el6 = dom.createTextNode("Trofeje");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "col-xs-2");
                var el5 = dom.createTextNode("\n                                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("h5");
                var el6 = dom.createTextNode("Body");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "col-xs-2");
                var el5 = dom.createTextNode("\n                                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("h5");
                var el6 = dom.createTextNode("Úspěšnost");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                dom.setAttribute(el1, "style", "margin-bottom: 20px; font-weight: bold;");
                var el2 = dom.createTextNode("\n                       ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-6");
                var el3 = dom.createTextNode("Skóre: ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "style", "font-weight:normal");
                var el4 = dom.createTextNode("(odevzdané / celkem odevzdané / celkem všechny)");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                       ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-2");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                       ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-2");
                dom.setAttribute(el2, "title", "body za odevzdané úlohy / maximum bodů za odevzdané úlohy / maximum bodů za všechny úlohy ve vlně");
                var el3 = dom.createTextNode("\n                           ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" / ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" / ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                       ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                       ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-2");
                dom.setAttribute(el2, "title", "úspěšnost odevzdaných úloh / úspěšnost všech úloh ve vlně");
                var el3 = dom.createTextNode("\n                           ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" % / ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" %\n                       ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("hr");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [7]);
                var element5 = dom.childAt(element4, [5]);
                var element6 = dom.childAt(element4, [7]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[2] = dom.createMorphAt(element5, 1, 1);
                morphs[3] = dom.createMorphAt(element5, 3, 3);
                morphs[4] = dom.createMorphAt(element5, 5, 5);
                morphs[5] = dom.createMorphAt(element6, 1, 1);
                morphs[6] = dom.createMorphAt(element6, 3, 3);
                return morphs;
              },
              statements: [["content", "res_wave.wave.caption", ["loc", [null, [127, 48], [127, 73]]]], ["block", "each", [["get", "res_wave.tasks", ["loc", [null, [149, 28], [149, 42]]]]], [], 0, null, ["loc", [null, [149, 20], [179, 29]]]], ["content", "res_wave.score", ["loc", [null, [185, 27], [185, 45]]]], ["content", "res_wave.max_score", ["loc", [null, [185, 48], [185, 70]]]], ["content", "res_wave.wave.sum_points", ["loc", [null, [185, 73], [185, 101]]]], ["content", "res_wave.percentage_submitted", ["loc", [null, [188, 27], [188, 60]]]], ["content", "res_wave.percentage_all", ["loc", [null, [188, 70], [188, 97]]]]],
              locals: ["res_wave"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 12
                },
                "end": {
                  "line": 193,
                  "column": 12
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "results", ["loc", [null, [126, 25], [126, 32]]]]], [], 0, null, ["loc", [null, [126, 17], [192, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 8
              },
              "end": {
                "line": 194,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "results", ["loc", [null, [124, 18], [124, 25]]]]], [], 0, null, ["loc", [null, [124, 12], [193, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 200,
                    "column": 24
                  },
                  "end": {
                    "line": 200,
                    "column": 108
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "achievement-small", [], ["model", ["subexpr", "@mut", [["get", "achievement", ["loc", [null, [200, 95], [200, 106]]]]], [], []]], ["loc", [null, [200, 69], [200, 108]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 199,
                  "column": 20
                },
                "end": {
                  "line": 201,
                  "column": 20
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", ["achievements"], ["class", "noContent"], 0, null, ["loc", [null, [200, 24], [200, 120]]]]],
            locals: ["achievement"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 218,
                    "column": 16
                  },
                  "end": {
                    "line": 218,
                    "column": 61
                  }
                },
                "moduleName": "ksi/templates/profile.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                return morphs;
              },
              statements: [["content", "no_tasks", ["loc", [null, [218, 45], [218, 57]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 205,
                  "column": 12
                },
                "end": {
                  "line": 219,
                  "column": 12
                }
              },
              "moduleName": "ksi/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" didn't work properly => got disabled ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "margin-top:40px");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h4");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" Org`s tasks ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" Org`s co-tasks ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 13, 13, contextualElement);
              return morphs;
            },
            statements: [["content", "tasks", ["loc", [null, [208, 28], [208, 37]]]], ["inline", "orgs-tasks", [], ["model", ["subexpr", "@mut", [["get", "model.tasks", ["loc", [null, [213, 35], [213, 46]]]]], [], []]], ["loc", [null, [213, 16], [213, 48]]]], ["inline", "orgs-tasks", [], ["model", ["subexpr", "@mut", [["get", "model.co_tasks", ["loc", [null, [216, 35], [216, 49]]]]], [], []]], ["loc", [null, [216, 16], [216, 51]]]], ["block", "unless", [["get", "model.any_task", ["loc", [null, [218, 26], [218, 40]]]]], [], 0, null, ["loc", [null, [218, 16], [218, 72]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 196,
                "column": 8
              },
              "end": {
                "line": 220,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "panel panel-achievements");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "panel-body");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.achievements", ["loc", [null, [199, 28], [199, 46]]]]], [], 0, null, ["loc", [null, [199, 20], [201, 29]]]], ["block", "unless", [["get", "model.show_solved", ["loc", [null, [205, 22], [205, 39]]]]], [], 1, null, ["loc", [null, [205, 12], [219, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 5
            },
            "end": {
              "line": 222,
              "column": 6
            }
          },
          "moduleName": "ksi/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-sm-12 col-md-3 col-print-4");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "style", "margin-top:30px");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3, "id", "profile-picture-profile");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "id", "name");
          dom.setAttribute(el2, "style", "margin-top:30px; margin-left:5px");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          dom.setAttribute(el3, "style", "text-align: left");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "id", "short-info");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "id", "profile-text");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" TODO: chceme sem odkaze na FB, Twitter, web? ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-sm-12 col-md-9 col-print-8");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-sm-12 col-md-9 col-print-12");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(element10, [1, 1]);
          var element12 = dom.childAt(element10, [3]);
          var element13 = dom.childAt(fragment, [5]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element11, 'src');
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element12, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(element12, 5, 5);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [5, 1]), 0, 0);
          morphs[5] = dom.createMorphAt(element10, 9, 9);
          morphs[6] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[7] = dom.createMorphAt(element13, 1, 1);
          morphs[8] = dom.createMorphAt(element13, 3, 3);
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["get", "model.profile_picture_r", ["loc", [null, [13, 28], [13, 51]]]]]]], ["content", "model.full_name", ["loc", [null, [16, 45], [16, 64]]]], ["content", "model.role_str", ["loc", [null, [17, 20], [17, 38]]]], ["block", "if", [["get", "fullProfile", ["loc", [null, [18, 22], [18, 33]]]]], [], 0, 1, ["loc", [null, [18, 16], [24, 23]]]], ["content", "model.short_info", ["loc", [null, [27, 37], [27, 57]]]], ["block", "if", [["get", "model.participant", ["loc", [null, [30, 18], [30, 35]]]]], [], 2, null, ["loc", [null, [30, 12], [42, 19]]]], ["block", "if", [["get", "model.show_solved", ["loc", [null, [46, 14], [46, 31]]]]], [], 3, null, ["loc", [null, [46, 8], [119, 15]]]], ["block", "if", [["get", "model.show_solved", ["loc", [null, [123, 14], [123, 31]]]]], [], 4, null, ["loc", [null, [123, 8], [194, 15]]]], ["block", "if", [["get", "model.organisator", ["loc", [null, [196, 14], [196, 31]]]]], [], 5, null, ["loc", [null, [196, 8], [220, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 225,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/profile.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "profile");
        dom.setAttribute(el1, "class", "container content-section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "log", [["get", "model", ["loc", [null, [1, 6], [1, 11]]]]], [], ["loc", [null, [1, 0], [1, 13]]]], ["block", "unless", [["get", "model.signed_in", ["loc", [null, [4, 15], [4, 30]]]]], [], 0, 1, ["loc", [null, [4, 5], [222, 17]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});