define("ksi/templates/task/discussion", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/task/discussion.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        dom.setAttribute(el1, "class", "visible-print");
        var el2 = dom.createTextNode("Diskuze");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "margin: 20px;");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "alert alert-info");
        var el3 = dom.createTextNode("\n            Pokud se chceš zeptat, proč ti nefunguje tvůj kód, pročti si prvně prosím příspěvky ostatních.\n            Ve většině případů se chyby mezi řešiteli opakují a na tvůj problém už možná někdo dostal odpověď.\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            Také prosím nevkládej své řešení do diskuze - kazilo by to zábavu ostatním řešitelům. Organizátoři mají přístup k tvému nejnovějšímu odevzdání sami.\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 3, 3);
        return morphs;
      },
      statements: [["inline", "thread-view", [], ["thread", ["subexpr", "@mut", [["get", "model.details.thread", ["loc", [null, [9, 25], [9, 45]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [9, 52], [9, 57]]]]], [], []], "taskId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [9, 65], [9, 73]]]]], [], []]], ["loc", [null, [9, 4], [9, 75]]]]],
      locals: [],
      templates: []
    };
  })());
});