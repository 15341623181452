define("ksi/models/user", ["exports", "ember-data", "ember", "ksi/config/environment"], function (exports, _emberData, _ember, _ksiConfigEnvironment) {
    exports["default"] = _emberData["default"].Model.extend({
        first_name: _emberData["default"].attr("string"),
        last_name: _emberData["default"].attr("string"),
        nick_name: _emberData["default"].attr("string"),
        full_name: _ember["default"].computed("first_name", "nick_name", "last_name", function () {
            if (!this.get("nick_name") || this.get("nick_name").length === 0) {
                return this.get("first_name") + ' ' + this.get("last_name");
            }
            return this.get("first_name") + ' "' + this.get("nick_name") + '" ' + this.get("last_name");
        }),
        surname_first_name: _ember["default"].computed("first_name", "last_name", function () {
            return this.get("last_name") + ' ' + this.get("first_name");
        }),
        profile_picture: _emberData["default"].attr("string"),
        profile_picture_r: _ember["default"].computed("profile_picture", "gender", "role", function () {
            var p = this.get("profile_picture");
            if (p) {
                return _ksiConfigEnvironment["default"].API_LOC + p;
            }

            var origin = "default";
            if (this.get("role") === "org" || this.get("role") === "admin") {
                origin = "org";
            }

            if (this.get("gender") === "female") {
                return "/img/avatar-" + origin + "-woman.svg";
            } else {
                return "/img/avatar-" + origin + ".svg";
            }
        }),
        short_info: _emberData["default"].attr("string"),
        gender: _emberData["default"].attr("string"),

        // Relevant only when user
        score: _emberData["default"].attr("number"),
        tasks_num: _emberData["default"].attr("number"),
        achievements: _emberData["default"].hasMany("achievement", { async: true }),
        school_name: _emberData["default"].attr("string"),
        addr_country: _emberData["default"].attr("string"),
        seasons: _emberData["default"].hasMany("year", { async: true }),
        successful: _emberData["default"].attr("boolean"),

        role: _emberData["default"].attr("string", { defaultValue: "participant" }),
        admin: _ember["default"].computed("role", function () {
            return this.get("role") === "admin";
        }),

        organisator: _ember["default"].computed("role", function () {
            return this.get("role") === "org" || this.get("role") === "admin";
        }),

        participant: _ember["default"].computed("role", function () {
            return this.get("role") === "participant";
        }),

        tester: _ember["default"].computed("role", function () {
            return this.get("role") === "tester";
        }),

        score_inverted: _ember["default"].computed("score", function () {
            return -this.get("score");
        }),

        // this property must be here, see ./profile.js
        show_solved: _ember["default"].computed("role", function () {
            return this.get("role") === "participant";
        }),

        // Relevant only when organisator
        tasks: _emberData["default"].hasMany("task", { defaultValue: [], async: true, inverse: 'author' }),
        co_tasks: _emberData["default"].hasMany("task", { defaultValue: [], async: true, inverse: 'co_author' }),
        email: _emberData["default"].attr("string"),

        any_task: _ember["default"].computed("tasks", "co_tasks", function () {
            return this.get("tasks.length") > 0 || this.get("co_tasks.length") > 0;
        }),

        role_str: _ember["default"].computed("gender", "role", function () {
            var ret = "";
            if (this.get("role") === "org" || this.get("role") === "admin") {
                ret = "organizátor";
            }
            if (this.get("role") === "participant") {
                ret = "řešitel";
            }
            if (this.get("role") === "tester") {
                ret = "tester";
            }
            if (this.get("gender") === "female") {
                ret = ret + "ka";
            }
            return ret;
        }),

        // Musi tu byt pro spravnou funkci endpointu /profile.
        signed_in: true

    });
});