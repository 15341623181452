define("ksi/transforms/files-list", ["exports", "ember-data", "ember", "ksi/config/environment"], function (exports, _emberData, _ember, _ksiConfigEnvironment) {
    exports["default"] = _emberData["default"].Transform.extend({
        serialize: function serialize(value) {
            if (value) {
                return value.get("files");
            }
        },
        deserialize: function deserialize(value) {
            /*return Ember.Object.create( {
                id: value['id'],
                filename: value['filename'],
                filepath: config.API_LOC + value['filename']
            });*/
            return _ember["default"].Object.create({
                files: value.map(function (x) {
                    x['filepath'] = _ksiConfigEnvironment["default"].API_LOC + '/submFiles/' + x['id'];
                    return x;
                })
            });
        }
    });
});