define("ksi/components/submitted-file", ["exports", "ember", "ksi/config/environment"], function (exports, _ember, _ksiConfigEnvironment) {
    exports["default"] = _ember["default"].Component.extend({
        session: _ember["default"].inject.service(),
        tagName: "",
        classNames: [],
        didInsertElement: function didInsertElement() {
            this._super();
            this.set("active", true);
        },
        actions: {
            del: function del() {
                if (!this.get("active")) {
                    return;
                }

                if (!confirm("Opravdu odstranit soubor " + this.get("file.filename") + "?")) {
                    return;
                }

                this.set("active", false);
                var self = this;
                this.get('session').authorize('authorizer:oauth2', function (header, content) {
                    _ember["default"].$.ajax({
                        url: self.get("file.filepath"),
                        data: JSON.stringify({}),
                        contentType: "application/json",
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader(header, content);
                        },
                        type: 'DELETE',
                        success: function success(data) {
                            if (data['status'] === "ok") {
                                self.sendAction("del", self.get("file.id"));
                            }
                        },
                        error: function error() {
                            self.set("active", true);
                        }
                    });
                });
            },
            down: function down() {
                var self = this;
                this.get('session').authorize('authorizer:oauth2', function (header, content) {
                    if (!self.get("file.filepath")) {
                        self.set("file.filepath", _ksiConfigEnvironment["default"].API_LOC + '/submFiles/' + self.get("file").id);
                    }
                    var request = new XMLHttpRequest();
                    request.open("GET", self.get("file.filepath"), true);
                    request.responseType = "blob";
                    request.setRequestHeader(header, content);
                    request.onload = function () {
                        if (this.status === 200) {
                            var file = window.URL.createObjectURL(this.response);
                            var a = document.createElement("a");
                            a.href = file;
                            a.download = this.response.name || self.get("file.filename");
                            document.body.appendChild(a);
                            a.click();
                            window.onfocus = function () {
                                document.body.removeChild(a);
                            };
                        }
                    };
                    request.send();
                });
            }
        }
    });
});