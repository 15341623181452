define("ksi/models/thread", ["exports", "ember-data"], function (exports, _emberData) {

    /**
     * Thread in discussion
     */
    exports["default"] = _emberData["default"].Model.extend({
        title: _emberData["default"].attr("string"),
        details: _emberData["default"].belongsTo("thread-detail", { async: true }),
        unread: _emberData["default"].attr("number"),
        posts_count: _emberData["default"].attr("number"),
        "public": _emberData["default"].attr("boolean", { "default": "true" }),
        year: _emberData["default"].belongsTo("year", { async: true })
    });
});