define('ksi/serializers/wave', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'waves') {
        return "wave";
      } else {
        return this._super(payloadKey);
      }
    },
    isNewSerializerAPI: true
  });
});