define("ksi/controllers/admin/wave-create", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),
        saving: false,

        caption: "",
        time_published: new Date(),
        garant: _ember["default"].computed.alias('model.users.firstObject'),
        index: _ember["default"].computed("model.waves", function () {
            return this.get("model.waves.length") + 1;
        }),

        actions: {
            'wave-create': function waveCreate() {
                var self = this;

                this.set("error_status", "");
                this.set("saving", true);

                this.get("store").createRecord('wave', {
                    caption: this.get("caption"),
                    garant: this.get("garant"),
                    time_published: this.get("time_published"),
                    index: this.get("index")
                }).save().then(function () {
                    self.set("saving", false);
                    self.transitionToRoute('admin/waves');
                }, function () {
                    self.set("saving", false);
                    self.set("error_status", "Chybová odpověď serveru! Kontaktuj organizátora.");
                });
            }
        }

    });
});