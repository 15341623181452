define("ksi/templates/task", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 121
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "id", "org-photo");
            dom.setAttribute(el1, "class", "inline");
            dom.setAttribute(el1, "style", "border-radius: 2px; width: 30px");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h5");
            dom.setAttribute(el1, "id", "org-name");
            dom.setAttribute(el1, "class", "inline");
            dom.setAttribute(el1, "style", "color: #39393a;text-decoration: none;");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element9, 'src');
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "model.author.profile_picture_r", ["loc", [null, [9, 117], [9, 147]]]]]]], ["content", "model.author.full_name", ["loc", [null, [10, 90], [10, 116]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 14
                },
                "end": {
                  "line": 13,
                  "column": 126
                }
              },
              "moduleName": "ksi/templates/task.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "id", "org-photo");
              dom.setAttribute(el1, "class", "inline");
              dom.setAttribute(el1, "style", "border-radius: 2px; width: 30px");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h5");
              dom.setAttribute(el1, "id", "org-name");
              dom.setAttribute(el1, "class", "inline");
              dom.setAttribute(el1, "style", "color: #39393a;text-decoration: none;");
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [0]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'src');
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["get", "model.co_author.profile_picture_r", ["loc", [null, [12, 124], [12, 157]]]]]]], ["content", "model.co_author.full_name", ["loc", [null, [13, 92], [13, 121]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 10
              },
              "end": {
                "line": 14,
                "column": 10
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["user-profile", ["get", "model.co_author", ["loc", [null, [12, 40], [12, 55]]]]], ["class", "noLinkDecoration"], 0, null, ["loc", [null, [12, 14], [13, 138]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 10
              },
              "end": {
                "line": 21,
                "column": 10
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "task-points inline");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "date-and-time", [["get", "model.time_deadline", ["loc", [null, [20, 58], [20, 77]]]], "cs"], [], ["loc", [null, [20, 42], [20, 84]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 10
              },
              "end": {
                "line": 23,
                "column": 10
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "task-points inline");
            var el2 = dom.createTextNode("v nekonečnu");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 28
              },
              "end": {
                "line": 31,
                "column": 105
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Zadání");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element7, 'href');
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [31, 82], [31, 91]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 28
              },
              "end": {
                "line": 32,
                "column": 109
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Statistika");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'href');
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [32, 82], [32, 91]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 28
              },
              "end": {
                "line": 33,
                "column": 106
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Diskuze");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element5, 'href');
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [33, 82], [33, 91]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 28
              },
              "end": {
                "line": 34,
                "column": 108
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Hodnocení");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'href');
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [34, 82], [34, 91]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 28
              },
              "end": {
                "line": 35,
                "column": 103
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("Řešení");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element3, 'href');
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [35, 80], [35, 89]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child9 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 28
                },
                "end": {
                  "line": 46,
                  "column": 28
                }
              },
              "moduleName": "ksi/templates/task.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "style", "text-align: right;");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "style", "padding: 5px;");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                               ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'href');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createUnsafeMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [42, 47], [42, 56]]]]]]], ["element", "action", ["hideUpdate"], [], ["loc", [null, [42, 82], [42, 105]]]], ["content", "reload_status", ["loc", [null, [43, 36], [43, 55]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 24
              },
              "end": {
                "line": 50,
                "column": 24
              }
            },
            "moduleName": "ksi/templates/task.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "nav navbar-nav navbar-right");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("a");
            var el4 = dom.createTextNode("↻");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [3, 0]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createAttrMorph(element2, 'href');
            morphs[2] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["block", "if", [["get", "reload_status", ["loc", [null, [40, 34], [40, 47]]]]], [], 0, null, ["loc", [null, [40, 28], [46, 35]]]], ["attribute", "href", ["concat", [["get", "view.href", ["loc", [null, [48, 43], [48, 52]]]]]]], ["element", "action", ["updateTask"], [], ["loc", [null, [48, 56], [48, 79]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/task.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "container content-section");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row task-title");
          dom.setAttribute(el2, "style", "margin-left: 20px");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3, "id", "name");
          dom.setAttribute(el3, "style", "color: black");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" TODO: center name and image ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          dom.setAttribute(el2, "style", "padding-left:5px; margin-left: 20px");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h5");
          dom.setAttribute(el3, "class", "inline");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "task-points inline");
          var el4 = dom.createTextNode("Max. ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          dom.setAttribute(el2, "style", "padding-left:5px; margin-left: 20px");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h5");
          dom.setAttribute(el3, "class", "inline");
          var el4 = dom.createTextNode("Termín odevzdání: ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row hidden-print container-fluid");
          dom.setAttribute(el2, "style", "margin-top: 20px");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("nav");
          dom.setAttribute(el3, "class", "navbar navbar-uloha");
          dom.setAttribute(el3, "role", "navigation");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "container-fluid");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "navbar-header-menu");
          dom.setAttribute(el5, "id", "bs-task-navbar");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("ul");
          dom.setAttribute(el6, "class", "nav navbar-nav");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createComment(" /.navbar ");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment(" /.container-fluid ");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("section");
          dom.setAttribute(el2, "class", "autojustify");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(element10, [5]);
          var element12 = dom.childAt(element11, [7]);
          var element13 = dom.childAt(element10, [9, 1, 1, 1]);
          var element14 = dom.childAt(element13, [1]);
          var morphs = new Array(14);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element10, [1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element11, 3, 3);
          morphs[3] = dom.createMorphAt(element11, 5, 5);
          morphs[4] = dom.createMorphAt(element12, 1, 1);
          morphs[5] = dom.createMorphAt(element12, 3, 3);
          morphs[6] = dom.createMorphAt(dom.childAt(element10, [7]), 3, 3);
          morphs[7] = dom.createMorphAt(element14, 1, 1);
          morphs[8] = dom.createMorphAt(element14, 3, 3);
          morphs[9] = dom.createMorphAt(element14, 5, 5);
          morphs[10] = dom.createMorphAt(element14, 7, 7);
          morphs[11] = dom.createMorphAt(element14, 9, 9);
          morphs[12] = dom.createMorphAt(element13, 3, 3);
          morphs[13] = dom.createMorphAt(dom.childAt(element10, [11]), 1, 1);
          return morphs;
        },
        statements: [["content", "model.title", ["loc", [null, [4, 47], [4, 64]]]], ["inline", "if", [["get", "model.co_author", ["loc", [null, [8, 34], [8, 49]]]], "Autoři:", "Autor:"], [], ["loc", [null, [8, 29], [8, 70]]]], ["block", "link-to", ["user-profile", ["get", "model.author", ["loc", [null, [9, 36], [9, 48]]]]], ["class", "noLinkDecoration"], 0, null, ["loc", [null, [9, 10], [10, 133]]]], ["block", "if", [["get", "model.co_author", ["loc", [null, [11, 16], [11, 31]]]]], [], 1, null, ["loc", [null, [11, 10], [14, 17]]]], ["content", "model.max_score", ["loc", [null, [15, 45], [15, 64]]]], ["content", "points_text", ["loc", [null, [15, 65], [15, 80]]]], ["block", "if", [["get", "model.time_deadline", ["loc", [null, [19, 16], [19, 35]]]]], [], 2, 3, ["loc", [null, [19, 10], [23, 17]]]], ["block", "link-to", ["task.assignment"], ["tagName", "li"], 4, null, ["loc", [null, [31, 28], [31, 117]]]], ["block", "link-to", ["task.statistics"], ["tagName", "li"], 5, null, ["loc", [null, [32, 28], [32, 121]]]], ["block", "link-to", ["task.discussion"], ["tagName", "li"], 6, null, ["loc", [null, [33, 28], [33, 118]]]], ["block", "link-to", ["task.evaluation"], ["tagName", "li"], 7, null, ["loc", [null, [34, 28], [34, 120]]]], ["block", "link-to", ["task.solution"], ["tagName", "li"], 8, null, ["loc", [null, [35, 28], [35, 115]]]], ["block", "if", [["get", "session.current_user.organisator", ["loc", [null, [38, 30], [38, 62]]]]], [], 9, null, ["loc", [null, [38, 24], [50, 31]]]], ["content", "outlet", ["loc", [null, [56, 12], [56, 22]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/task.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ale-rt", [], ["type", "danger", "text", "Tato úloha je uzamčena!"], ["loc", [null, [60, 4], [60, 59]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/task.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.active", ["loc", [null, [1, 6], [1, 18]]]]], [], 0, 1, ["loc", [null, [1, 0], [61, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});