define("ksi/routes/index", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        articles_limit: 2,
        model: function model() {
            return _ember["default"].RSVP.hash({
                articles: this.store.query("article", {
                    _start: 0,
                    _limit: this.get("articles_limit")
                }),
                organisators: this.store.query("user", { filter: "organisators", sort: "score" })
            });
        }
    });
});