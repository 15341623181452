define("ksi/components/thread-post", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        session: _ember["default"].inject.service(),
        depth: 0, // hloubka vlakna v diskuzi
        max_depth: 3, // nejvetsi hloubka, ve ktere jeste zanorovat

        edit_progress: false,
        edit_error: "",
        react_progress: false,
        react_error: "",
        react_error_show: false,

        depth_inc: _ember["default"].computed("depth", function () {
            return this.get("depth") + 1;
        }),

        // jestli byl presahnut limit zanorovani
        depth_exceeded: _ember["default"].computed("depth", "max_depth", function () {
            return this.get("depth") >= this.get("max_depth");
        }),

        mark_as_read: function mark_as_read(post) {
            var self = this;
            post.set("is_new", false);
            post.get("parent").then(function (parent_post) {
                if (parent_post) {
                    self.mark_as_read(parent_post);
                }
            });
        },

        actions: {
            openUsersSubmission: function openUsersSubmission() {
                var url = _ember["default"].$(this.element).find("a.toSubmissions").last()[0].href;
                if (url !== undefined) {
                    window.open(url, '_blank');
                }
            },
            react: function react() {
                this.set("is_reacting", !this.get("is_reacting"));
                this.mark_as_read(this.get("model"));
                this.set("content_error", undefined);
                this.set("response_text", "");
            },
            send: function send() {
                var self = this;
                this.set("react_error", "");

                if (!this.get("response_text")) {
                    this.set("react_error", "Nelze odeslat prázdný příspěvek");
                    this.set("react_error_show", true);
                    return;
                }

                this.set("react_progress", true);

                var post = this.get("store").createRecord("post", {
                    body: self.get("response_text"),
                    parent: self.get("model"),
                    thread: self.get("model.thread"),
                    temporary: true
                });

                post.save().then(function () {
                    post.set("temporary", false);
                    self.set("react_progress", false);
                    self.set("is_reacting", false);
                    self.set("response_text", "");
                    _ember["default"].run.later(self, function () {
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    }, 500);
                }, function (resp) {
                    // fail
                    post.destroyRecord();
                    self.set("react_progress", false);
                    var e = "Nepodařilo se uložit příspěvek! Pokud si myslíš, že chyba není na tvé straně, kontaktuj organizátora.<br>" + resp.message;
                    if (resp.errors[0]) {
                        e += "<br>" + resp.errors[0].status + " : " + resp.errors[0].title;
                    }
                    self.set("react_error", e);
                });
            },
            "delete": function _delete() {
                if (this.get("model").reaction) {
                    if (!confirm("!!! POZOR !!! Na tento příspěvek jsou navázané reakce. Když smažeš tento příspěvek, ze všech přímých potomků se stanou top level posty. Web sám reload nezvládne, udělej manuální refresh pomocí F5. !!!")) {
                        return;
                    }
                }
                if (!confirm("Opravdu smazat příspěvek?")) {
                    return;
                }
                this.get("model").deleteRecord();
                this.get("model").save();
            },
            edit: function edit() {
                this.set("content_bak", this.get("model.body"));
                this.set("is_editing", true);
                this.set("edit_error", undefined);
                this.set("edit_progress", undefined);
            },
            cancel: function cancel() {
                this.set("model.body", this.get("content_bak"));
                this.set("is_editing", false);
                _ember["default"].run.later(this, function () {
                    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                }, 500);
            },
            save: function save() {
                var self = this;
                this.set("edit_progress", true);
                this.set("edit_error", "");

                this.get("model").save().then(function () {
                    self.set("is_editing", false);
                    self.set("edit_progress", false);
                    _ember["default"].run.later(self, function () {
                        MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
                    }, 500);
                }, function (resp) {
                    self.set("edit_progress", false);
                    var e = "Nepodařilo se uložit příspěvek! Pokud si myslíš, že chyba není na tvé straně, kontaktuj organizátora.<br>" + resp.message;
                    if (resp.errors[0]) {
                        e += "<br>" + resp.errors[0].status + " : " + resp.errors[0].title;
                    }
                    self.set("edit_error", e);
                });
            }
        },
        allow_edit: _ember["default"].computed("session.current_user", "model.thread.year.sealed", function () {
            return this.get("session.current_user.organisator") && !this.get("model.thread.year.sealed");
        }),
        isOrg: _ember["default"].computed("session.current_user", function () {
            return this.get("session.current_user.organisator");
        }),
        allow_delete: _ember["default"].computed("session.current_user", "model.thread.year.sealed", function () {
            return this.get("session.current_user.organisator") && !this.get("model.thread.year.sealed");
        }),
        is_reacting: false,
        content_error: undefined
    });
});