define("ksi/routes/profile", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            this.store.unloadAll("profile");
            return this.store.find("profile", "");
        },

        afterModel: function afterModel() {
            var self = this;
            return this.store.findAll("wave").then(function (result) {
                self.set("waves", result);
            });
        },

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('waves', this.get('waves'));
            controller.set('fullProfile', true);
        },

        title: "KSI: Profil"
    });
});