define('ksi/models/correction', ['exports', 'ember-data', 'model-fragments', 'ember'], function (exports, _emberData, _modelFragments, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        task_id: _emberData['default'].belongsTo("task"),
        state: _emberData['default'].attr("string"),
        user: _emberData['default'].belongsTo("user"),
        comment: _emberData['default'].belongsTo("thread", { async: true }),
        achievements: _emberData['default'].hasMany("achievement"),
        modules: _modelFragments['default'].fragmentArray('modulefragment'),

        corrected: _ember['default'].computed("state", function () {
            return this.get("state") === "corrected";
        }),
        notcorrected: _ember['default'].computed("state", function () {
            return this.get("state") === "notcorrected";
        }),
        style: _ember['default'].computed("state", function () {
            if (this.get("state") === "corrected") {
                return "correct";
            }
            return "incorrect";
        })
    });
});