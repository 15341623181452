define("ksi/routes/user-profile", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model(params) {
            return this.store.find("user", params["profile_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("full_name");
        },
        renderTemplate: function renderTemplate() {
            this.render("profile", { controller: 'profile' });
        },
        title: function title(tokens) {
            return "KSI: profil – " + tokens.pop();
        },
        setupController: function setupController(controller, model) {
            this.controllerFor('profile').setProperties({ content: model, fullProfile: false });
        }
    });
});