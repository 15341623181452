define("ksi/routes/admin/wave-edit", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model(params) {
            return _ember["default"].RSVP.hash({
                wave: this.store.find("wave", params["wave_id"]),
                users: this.store.query("user", { filter: "organisators" })
            });
        },
        title: "KSI: Úprava vlny",
        actions: {
            willTransition: function willTransition() {
                this.controller.set('save_status', "");
                this.controller.set('error_status', "");
            }
        }
    });
});