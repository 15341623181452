define('ksi/routes/articles', ['exports', 'ember', 'ksi/mixins/reset-scroll'], function (exports, _ember, _ksiMixinsResetScroll) {
    exports['default'] = _ember['default'].Route.extend(_ksiMixinsResetScroll['default'], {
        offset: 0,
        limit: 3,
        queryParams: {
            page: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var page;
            if (params.page) {
                page = params.page;
                page = isNaN(page) ? 1 : Math.floor(Math.abs(page));
                this.set('offset', (page - 1) * this.get('limit'));
            }

            return this.store.query("article", {
                _start: this.get("offset"),
                _limit: this.get("limit")
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setProperties({
                offset: this.get("offset"),
                limit: this.get("limit")
            });
        },
        title: "KSI: Novinky"
    });
});