define('ksi/controllers/login', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            login: function login() {
                var _this = this;

                var _getProperties = this.getProperties('identification', 'password');

                var identification = _getProperties.identification;
                var password = _getProperties.password;

                this.set('password', "");
                this.get('session').authenticate('authenticator:oauth2', identification, password).then(null, function (error) {
                    _this.set('errorMessage', error.error);
                });
            }
        }
    });
});