define("ksi/routes/admin/correcting", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            return _ember["default"].RSVP.hash({
                'corr-info': this.get('store').findAll("corrections_info"),
                'orgs': this.store.query("user", { filter: "organisators", sort: "score" }),
                'achievements': this.store.find("achievement")
            });
        },
        titleToken: function titleToken() {
            return "Opravování";
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.set("corrections", []);
            }
        }
    });
});