define("ksi/transforms/prerequisite", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
	exports["default"] = _emberData["default"].Transform.extend({
		serialize: function serialize(value) {
			return value.get("groups");
		},
		deserialize: function deserialize(value) {
			return _ember["default"].Object.create({
				groups: value
			});
		}
	});
});