define("ksi/models/task-detail", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        body: _emberData["default"].attr("string"),
        solution: _emberData["default"].attr("string"),
        thread: _emberData["default"].belongsTo("thread"),
        modules: _emberData["default"].hasMany("module"),

        best_scores: _emberData["default"].hasMany("user-score"), // Top 5

        comment: _emberData["default"].belongsTo("thread"),
        achievements: _emberData["default"].hasMany("achievements", { async: true })
    });
});