define("ksi/controllers/tasks", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),
        actions: {
            sub: function sub(id) {
                this.transitionTo("task.submission", id);
            },
            assign: function assign(id) {
                this.transitionTo("task.assignment", id);
            },
            stat: function stat(id) {
                this.transitionTo("task.statistics", id);
            },
            discuss: function discuss(id) {
                this.transitionTo("task.discussion", id);
            },
            solution: function solution(id) {
                this.transitionTo("task.solution", id);
            }
        }
    });
});