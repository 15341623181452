define("ksi/controllers/admin/articles", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),
        sortProperties: ["time_published:desc"],

        articles: _ember["default"].computed.sort("model", "sortProperties"),

        actions: {
            'article-delete': function articleDelete(article) {
                if (!confirm("Opravdu odstranit článek " + article.get("title") + "?")) {
                    return;
                }

                article.set("deleting", true);
                article.destroyRecord().then({}, function (error) {
                    article.set("deleting", false);
                    alert("Článek se nepodařilo odstranit, kontaktuj administrátora:\n" + error);
                });
            }
        }
    });
});