define("ksi/instance-initializers/ember-i18n", ["exports", "ember", "ember-i18n/legacy-helper", "ksi/config/environment"], function (exports, _ember, _emberI18nLegacyHelper, _ksiConfigEnvironment) {
  exports["default"] = {
    name: 'ember-i18n',

    initialize: function initialize(instance) {
      var defaultLocale = (_ksiConfigEnvironment["default"].i18n || {}).defaultLocale;
      if (defaultLocale === undefined) {
        _ember["default"].warn('ember-i18n did not find a default locale; falling back to "en".');
        defaultLocale = 'en';
      }
      var key = 'service:i18n';
      var i18n = instance.lookup ? instance.lookup(key) : instance.container.lookup(key);
      i18n.set('locale', defaultLocale);

      if (_emberI18nLegacyHelper["default"] != null) {
        _ember["default"].HTMLBars._registerHelper('t', _emberI18nLegacyHelper["default"]);
      }
    }
  };
});