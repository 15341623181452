define("ksi/templates/components/content-component", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 10,
                    "column": 16
                  },
                  "end": {
                    "line": 10,
                    "column": 109
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary");
                var el2 = dom.createTextNode("Vybrat");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [0]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element9);
                return morphs;
              },
              statements: [["element", "action", ["select", ["get", "file", ["loc", [null, [10, 87], [10, 91]]]]], [], ["loc", [null, [10, 69], [10, 93]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 16
                  },
                  "end": {
                    "line": 13,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary disabled");
                var el2 = dom.createTextNode("Mažu...");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 16
                  },
                  "end": {
                    "line": 15,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary");
                var el2 = dom.createTextNode("Smazat");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element8);
                return morphs;
              },
              statements: [["element", "action", ["delete_file", ["get", "file", ["loc", [null, [14, 78], [14, 82]]]]], [], ["loc", [null, [14, 55], [14, 84]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 12
                },
                "end": {
                  "line": 16,
                  "column": 12
                }
              },
              "moduleName": "ksi/templates/components/content-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "selectable", ["loc", [null, [10, 22], [10, 32]]]]], [], 0, null, ["loc", [null, [10, 16], [10, 116]]]], ["block", "if", [["get", "file.deleting", ["loc", [null, [11, 22], [11, 35]]]]], [], 1, 2, ["loc", [null, [11, 16], [15, 23]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 19,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/components/content-component.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "target", "_blank");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn-xs btn-primary");
            var el3 = dom.createTextNode("Kopírovat URL");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [1, 0]);
            var element12 = dom.childAt(element10, [5]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            morphs[1] = dom.createAttrMorph(element11, 'href');
            morphs[2] = dom.createMorphAt(element11, 0, 0);
            morphs[3] = dom.createMorphAt(element10, 3, 3);
            morphs[4] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "file.selected", ["loc", [null, [6, 24], [6, 37]]]], "selected"], [], ["loc", [null, [6, 19], [6, 50]]]]]]], ["attribute", "href", ["concat", [["get", "endpoint", ["loc", [null, [7, 31], [7, 39]]]], ["get", "file.file", ["loc", [null, [7, 43], [7, 52]]]]]]], ["content", "file.file", ["loc", [null, [7, 72], [7, 85]]]], ["block", "unless", [["get", "file.selected", ["loc", [null, [9, 22], [9, 35]]]]], [], 0, null, ["loc", [null, [9, 12], [16, 23]]]], ["element", "action", ["copy-to-clipboard", ["get", "file", ["loc", [null, [17, 76], [17, 80]]]]], [], ["loc", [null, [17, 47], [17, 82]]]]],
          locals: ["file"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 4
              },
              "end": {
                "line": 21,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/components/content-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Zatím žádné soubory");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/components/content-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "files", ["loc", [null, [5, 12], [5, 17]]]]], [], 0, 1, ["loc", [null, [5, 4], [21, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 37,
                    "column": 20
                  },
                  "end": {
                    "line": 37,
                    "column": 113
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary");
                var el2 = dom.createTextNode("Vybrat");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [0]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["element", "action", ["select", ["get", "file", ["loc", [null, [37, 91], [37, 95]]]]], [], ["loc", [null, [37, 73], [37, 97]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 20
                  },
                  "end": {
                    "line": 40,
                    "column": 20
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary disabled");
                var el2 = dom.createTextNode("Mažu...");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 20
                  },
                  "end": {
                    "line": 42,
                    "column": 20
                  }
                },
                "moduleName": "ksi/templates/components/content-component.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-xs btn-primary");
                var el2 = dom.createTextNode("Smazat");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [["element", "action", ["delete_file", ["get", "file", ["loc", [null, [41, 82], [41, 86]]]]], [], ["loc", [null, [41, 59], [41, 88]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 16
                },
                "end": {
                  "line": 43,
                  "column": 16
                }
              },
              "moduleName": "ksi/templates/components/content-component.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "selectable", ["loc", [null, [37, 26], [37, 36]]]]], [], 0, null, ["loc", [null, [37, 20], [37, 120]]]], ["block", "if", [["get", "file.deleting", ["loc", [null, [38, 26], [38, 39]]]]], [], 1, 2, ["loc", [null, [38, 20], [42, 27]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/components/content-component.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "margin-bottom: 20px; padding-bottom: 5px; height: 225px;");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "target", "_blank");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4, "style", "max-width: 200px; max-height: 200px;");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "float: top;");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "btn-xs btn-primary");
            var el4 = dom.createTextNode("Kopírovat URL");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [1, 1]);
            var element5 = dom.childAt(element4, [0]);
            var element6 = dom.childAt(element3, [3]);
            var element7 = dom.childAt(element6, [3]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createAttrMorph(element4, 'href');
            morphs[2] = dom.createAttrMorph(element5, 'src');
            morphs[3] = dom.createAttrMorph(element5, 'title');
            morphs[4] = dom.createAttrMorph(element5, 'alt');
            morphs[5] = dom.createMorphAt(element6, 1, 1);
            morphs[6] = dom.createElementMorph(element7);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["col-xs-12 col-sm-4 col-md-3 ", ["subexpr", "if", [["get", "file.selected", ["loc", [null, [31, 53], [31, 66]]]], "selected"], [], ["loc", [null, [31, 48], [31, 79]]]]]]], ["attribute", "href", ["concat", [["get", "endpoint", ["loc", [null, [33, 27], [33, 35]]]], ["get", "file.file", ["loc", [null, [33, 39], [33, 48]]]]]]], ["attribute", "src", ["concat", [["get", "endpoint", ["loc", [null, [33, 80], [33, 88]]]], ["get", "file.file", ["loc", [null, [33, 92], [33, 101]]]]]]], ["attribute", "title", ["concat", [["get", "file.file", ["loc", [null, [33, 114], [33, 123]]]]]]], ["attribute", "alt", ["concat", [["get", "file.file", ["loc", [null, [33, 134], [33, 143]]]]]]], ["block", "unless", [["get", "file.selected", ["loc", [null, [36, 26], [36, 39]]]]], [], 0, null, ["loc", [null, [36, 16], [43, 27]]]], ["element", "action", ["copy-to-clipboard", ["get", "file", ["loc", [null, [44, 80], [44, 84]]]]], [], ["loc", [null, [44, 51], [44, 86]]]]],
          locals: ["file"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "ksi/templates/components/content-component.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Zatím žádné soubory");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/components/content-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "gallery");
          dom.setAttribute(el1, "align", "center");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "files", ["loc", [null, [30, 12], [30, 17]]]]], [], 0, 1, ["loc", [null, [30, 4], [49, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 12
              },
              "end": {
                "line": 68,
                "column": 12
              }
            },
            "moduleName": "ksi/templates/components/content-component.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "file", ["loc", [null, [67, 20], [67, 28]]]]],
          locals: ["file"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 4
            },
            "end": {
              "line": 72,
              "column": 4
            }
          },
          "moduleName": "ksi/templates/components/content-component.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("K nahrání:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [7]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
          morphs[1] = dom.createAttrMorph(element0, 'class');
          morphs[2] = dom.createElementMorph(element0);
          morphs[3] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "to_upload", ["loc", [null, [66, 20], [66, 29]]]]], [], 0, null, ["loc", [null, [66, 12], [68, 21]]]], ["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "uploading", ["loc", [null, [70, 44], [70, 53]]]], "disabled"], [], ["loc", [null, [70, 39], [70, 66]]]]]]], ["element", "action", ["submit"], [], ["loc", [null, [70, 68], [70, 87]]]], ["inline", "if", [["get", "uploading", ["loc", [null, [71, 17], [71, 26]]]], "Nahrávám...", "Nahrát soubory"], [], ["loc", [null, [71, 12], [71, 59]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 78,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/components/content-component.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Content jako seznam souboru ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" Content jako galerie ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "fileUpload btn btn-primary");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "glyphicon glyphicon-open");
        dom.setAttribute(el3, "aria-hidden", "true");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Přidat soubory");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [8]);
        var element14 = dom.childAt(element13, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[2] = dom.createElementMorph(element14);
        morphs[3] = dom.createMorphAt(element13, 3, 3);
        morphs[4] = dom.createMorphAt(element13, 5, 5);
        morphs[5] = dom.createMorphAt(element13, 7, 7);
        morphs[6] = dom.createMorphAt(element13, 9, 9);
        return morphs;
      },
      statements: [["block", "if", [["get", "type_list", ["loc", [null, [2, 6], [2, 15]]]]], [], 0, null, ["loc", [null, [2, 0], [24, 7]]]], ["block", "if", [["get", "type_gallery", ["loc", [null, [28, 6], [28, 18]]]]], [], 1, null, ["loc", [null, [28, 0], [51, 7]]]], ["element", "action", ["select_files"], [], ["loc", [null, [55, 44], [55, 69]]]], ["inline", "file-input", [], ["multiple", true, "class", "upload", "endpoint", ["subexpr", "@mut", [["get", "upload_endpoint", ["loc", [null, [60, 55], [60, 70]]]]], [], []], "id", ["subexpr", "construct-id", ["c-upload", ["get", "model.id", ["loc", [null, [60, 99], [60, 107]]]]], [], ["loc", [null, [60, 74], [60, 108]]]], "file_list", "file_list", "status", "status", "upload_finished", "upload_finished", "upload_failed", "upload_failed", "progress", "progress", "actionReceiver", ["subexpr", "@mut", [["get", "f_input", ["loc", [null, [60, 246], [60, 253]]]]], [], []]], ["loc", [null, [60, 4], [60, 255]]]], ["block", "if", [["get", "to_upload", ["loc", [null, [62, 10], [62, 19]]]]], [], 2, null, ["loc", [null, [62, 4], [72, 11]]]], ["inline", "ale-rt", [], ["type", "info", "text", ["subexpr", "@mut", [["get", "progress_msg", ["loc", [null, [74, 30], [74, 42]]]]], [], []]], ["loc", [null, [74, 4], [74, 44]]]], ["inline", "ale-rt", [], ["type", "danger", "show", ["subexpr", "@mut", [["get", "show_error", ["loc", [null, [75, 32], [75, 42]]]]], [], []], "closeBtn", true, "text", ["subexpr", "@mut", [["get", "general_error", ["loc", [null, [75, 62], [75, 75]]]]], [], []]], ["loc", [null, [75, 4], [75, 77]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});