define("ksi/components/picture-upload", ["exports", "ember-uploader", "ember", "ember-component-inbound-actions/inbound-actions", "ksi/config/environment"], function (exports, _emberUploader, _ember, _emberComponentInboundActionsInboundActions, _ksiConfigEnvironment) {
    exports["default"] = _emberUploader["default"].FileField.extend(_emberComponentInboundActionsInboundActions["default"], {
        session: _ember["default"].inject.service(),

        url: _ksiConfigEnvironment["default"]["API_LOC"] + "/profile/picture",
        supported_ext: ["jpg", "jpeg", "png", "gif"],
        classNames: ["hide"],
        attributeBindings: ["multiple", "accept"],
        accept: (function () {
            return this.get("supported_ext").map(function (i) {
                return "." + i;
            }).toString();
        }).property("supported_ext"),
        filesDidChange: function filesDidChange(files) {
            var self = this;
            for (var i = 0; i !== files.length; i++) {
                var file = files.item(i).name;
                if (this.get("supported_ext").indexOf(file.split(".").pop().toLowerCase()) === -1) {
                    alert("Nepodporovaný typ souboru: " + file);
                    return;
                }
            }
            var uploader = _emberUploader["default"].Uploader.extend({
                url: self.get("url"),
                ajaxSettings: function ajaxSettings() {
                    var settings = this._super.apply(this, arguments);
                    settings['headers'] = {};
                    self.get("session").authorize('authorizer:oauth2', function (headerName, headerValue) {
                        settings.headers[headerName] = headerValue;
                    });
                    return settings;
                }
            }).create();

            uploader.on("didUpload", function () {
                self.sendAction("upload_finished");
            });

            uploader.on("didError", function (jqXHR, textStatus, errorThrown) {
                self.sendAction("upload_failed", textStatus, errorThrown);
            });

            if (!_ember["default"].isEmpty(files)) {
                uploader.upload(files[0]);
            }
        }
    });
});