define("ksi/routes/thread", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model(params) {
            return this.store.find("thread", params["thread_id"]);
        },
        titleToken: function titleToken(model) {
            return model.get("title");
        },
        title: function title(tokens) {
            return "KSI: " + tokens.pop();
        },
        actions: {
            willTransition: function willTransition() {
                this.controller.mark_thread_as_read();
            }
        },
        renderTemplate: function renderTemplate(controller, model) {
            this._super(controller, model);
            _ember["default"].run.later(this, function () {
                MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
            }, 500);
        }
    });
});