define("ksi/controllers/thread", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),

        mark_post_as_read: function mark_post_as_read(post) {
            var self = this;
            post.set("is_new", false);
            var reactions = post.get("reaction");
            reactions.forEach(function (post) {
                self.mark_post_as_read(post);
            });
        },

        mark_thread_as_read: function mark_thread_as_read() {
            var self = this;
            var root = this.get("model.details.root_posts");
            root.forEach(function (post) {
                self.mark_post_as_read(post);
            });
        }

    });
});