define("ksi/components/pagination-button", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Component.extend({
        tagName: "button",
        classNames: "btn btn-default".w(),
        attributeBindings: ["disabled"],
        enabled: true,
        disabled: _ember["default"].computed.not("enabled"),
        action: null,
        click: function click() {
            this.sendAction();
            //window.scrollTo(0, 0);
            _ember["default"].$("html, body").animate({ scrollTop: 0 }, "slow"); // ToDo: This isn't working!
        }
    });
});