define("ksi/transforms/sortable-list", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
	exports["default"] = _emberData["default"].Transform.extend({
		serialize: function serialize(value) {
			if (value) {
				return {
					fixed: value.get("fixed"),
					movable: value.get("movable")
				};
			}
			return {};
		},
		deserialize: function deserialize(value) {
			return _ember["default"].Object.create(value);
		}
	});
});