define("ksi/templates/task/evaluation", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 14
                    },
                    "end": {
                      "line": 23,
                      "column": 98
                    }
                  },
                  "moduleName": "ksi/templates/task/evaluation.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "achievement-small", [], ["model", ["subexpr", "@mut", [["get", "achievement", ["loc", [null, [23, 85], [23, 96]]]]], [], []]], ["loc", [null, [23, 59], [23, 98]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 12
                  },
                  "end": {
                    "line": 24,
                    "column": 12
                  }
                },
                "moduleName": "ksi/templates/task/evaluation.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "link-to", ["achievements"], ["class", "noContent"], 0, null, ["loc", [null, [23, 14], [23, 110]]]]],
              locals: ["achievement"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 6
                },
                "end": {
                  "line": 27,
                  "column": 6
                }
              },
              "moduleName": "ksi/templates/task/evaluation.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "margin-top:30px");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h5");
              var el3 = dom.createTextNode("Trofeje");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "panel panel-achievements");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "panel-body");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "model.details.achievements", ["loc", [null, [22, 20], [22, 46]]]]], [], 0, null, ["loc", [null, [22, 12], [24, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 18
                    },
                    "end": {
                      "line": 51,
                      "column": 18
                    }
                  },
                  "moduleName": "ksi/templates/task/evaluation.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "module.score.reviewed_by.full_name", ["loc", [null, [50, 20], [50, 58]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 16
                  },
                  "end": {
                    "line": 52,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/task/evaluation.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "link-to", ["user-profile", ["get", "module.score.reviewed_by", ["loc", [null, [49, 44], [49, 68]]]]], [], 0, null, ["loc", [null, [49, 18], [51, 30]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 52,
                    "column": 16
                  },
                  "end": {
                    "line": 54,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/task/evaluation.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  –\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 16
                  },
                  "end": {
                    "line": 59,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/task/evaluation.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("/");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["content", "module.score.score", ["loc", [null, [58, 18], [58, 40]]]], ["content", "module.max_score", ["loc", [null, [58, 41], [58, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 16
                  },
                  "end": {
                    "line": 61,
                    "column": 16
                  }
                },
                "moduleName": "ksi/templates/task/evaluation.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  –\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 10
                },
                "end": {
                  "line": 65,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/task/evaluation.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "style", "text-align: center;");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "style", "text-align: center;");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
              return morphs;
            },
            statements: [["content", "module.name", ["loc", [null, [46, 46], [46, 63]]]], ["block", "if", [["get", "module.score.is_corrected", ["loc", [null, [48, 22], [48, 47]]]]], [], 0, 1, ["loc", [null, [48, 16], [54, 23]]]], ["block", "if", [["get", "module.score.is_corrected", ["loc", [null, [57, 22], [57, 47]]]]], [], 2, 3, ["loc", [null, [57, 16], [61, 23]]]], ["inline", "if", [["get", "module.score.is_corrected", ["loc", [null, [63, 23], [63, 48]]]], "Opraveno", "Neopraveno"], [], ["loc", [null, [63, 18], [63, 74]]]]],
            locals: ["module"],
            templates: [child0, child1, child2, child3]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 6
                },
                "end": {
                  "line": 76,
                  "column": 6
                }
              },
              "moduleName": "ksi/templates/task/evaluation.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "margin-top:50px");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h5");
              var el3 = dom.createTextNode("Komentář ke tvému řešení:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "thread-view", [], ["thread", ["subexpr", "@mut", [["get", "model.details.comment", ["loc", [null, [75, 29], [75, 50]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [75, 57], [75, 62]]]]], [], []]], ["loc", [null, [75, 8], [75, 64]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 78,
                "column": 2
              }
            },
            "moduleName": "ksi/templates/task/evaluation.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "margin:20px");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            dom.setAttribute(el2, "class", "visible-print");
            var el3 = dom.createTextNode("Hodnocení");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h5");
            var el3 = dom.createTextNode("Skóre:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("hr");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "progress");
            dom.setAttribute(el2, "style", "margin-top:20px");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "progress-bar progress-bar-warning progress-bar-ksi");
            dom.setAttribute(el3, "role", "progressbar");
            dom.setAttribute(el3, "aria-valuenow", "60");
            dom.setAttribute(el3, "aria-valuemin", "0");
            dom.setAttribute(el3, "aria-valuemax", "10");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("b");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" /");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "margin-top:30px");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("Výsledky");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("hr");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("table");
            dom.setAttribute(el2, "class", "table table-striped table-hover-forum ");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("thead");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tr");
            dom.setAttribute(el4, "style", "height:45px");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("th");
            dom.setAttribute(el5, "style", "text-align: center;");
            var el6 = dom.createTextNode("Název úlohy");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("th");
            var el6 = dom.createTextNode("Opravující");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("th");
            dom.setAttribute(el5, "style", "text-align: center;");
            var el6 = dom.createTextNode("Body");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("th");
            var el6 = dom.createTextNode("Stav");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tbody");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [7, 1]);
            var element3 = dom.childAt(element2, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element2, 'style');
            morphs[1] = dom.createMorphAt(element3, 0, 0);
            morphs[2] = dom.createMorphAt(element3, 2, 2);
            morphs[3] = dom.createMorphAt(element1, 9, 9);
            morphs[4] = dom.createMorphAt(dom.childAt(element1, [13, 3]), 1, 1);
            morphs[5] = dom.createMorphAt(element1, 15, 15);
            return morphs;
          },
          statements: [["attribute", "style", ["get", "progress_width", ["loc", [null, [9, 159], [9, 173]]]]], ["content", "sum", ["loc", [null, [10, 19], [10, 26]]]], ["content", "model.max_score", ["loc", [null, [10, 33], [10, 52]]]], ["block", "if", [["get", "model.details.achievements", ["loc", [null, [14, 12], [14, 38]]]]], [], 0, null, ["loc", [null, [14, 6], [27, 13]]]], ["block", "each", [["get", "model.details.modules", ["loc", [null, [44, 18], [44, 39]]]]], [], 1, null, ["loc", [null, [44, 10], [65, 19]]]], ["block", "if", [["get", "model.details.comment", ["loc", [null, [69, 12], [69, 33]]]]], [], 2, null, ["loc", [null, [69, 6], [76, 13]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 2
              },
              "end": {
                "line": 80,
                "column": 2
              }
            },
            "moduleName": "ksi/templates/task/evaluation.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "ale-rt", [], ["type", "info", "text", "Tuto úlohu jsi zatím neodevzdal."], ["loc", [null, [79, 4], [79, 66]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1
            },
            "end": {
              "line": 81,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/task/evaluation.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "submitted", ["loc", [null, [2, 8], [2, 17]]]]], [], 0, 1, ["loc", [null, [2, 2], [80, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/task/evaluation.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ale-rt", [], ["type", "danger", "text", "Nejsi přihlášen"], ["loc", [null, [82, 2], [82, 49]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/task/evaluation.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [1, 7], [1, 30]]]]], [], 0, 1, ["loc", [null, [1, 1], [83, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});