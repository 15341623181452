define("ksi/controllers/admin/forum", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),

        published_waves: _ember["default"].computed.filter('model', function (wave) {
            return wave.get("public");
        }),
        wave: _ember["default"].computed.alias('published_waves.lastObject'),

        threads: [],

        load_threads: (function () {
            var self = this;

            if (self.get("wave") === undefined) {
                self.set("threads", []);
                return;
            }

            self.set("loading", true);

            self.get("store").query("thread", { "wave": self.get("wave.id") }).then(function (p) {
                self.set("threads", p);
                self.set("loading", false);
            }, function (error) {
                console.log(error);
                self.set("loading", false);
                self.set("threads", []);
                alert("Nepodařilo se načíst data ze serveru : " + error.message);
            });
        }).observes("wave", "model"),

        actions: {
            'load': function load() {
                this.load_threads();
            }

        }
    });
});