define('ksi/controllers/application', ['exports', 'ember', 'ksi/config/environment', 'ember-simple-auth/configuration'], function (exports, _ember, _ksiConfigEnvironment, _emberSimpleAuthConfiguration) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service(),
        login_error_message: undefined,
        in_progress: false,
        feedback_error: "",
        feedback_sending: false,
        feedback_sent: false,

        actions: {
            login: function login() {
                var self = this;

                var _getProperties = this.getProperties('identification', 'password');

                var identification = _getProperties.identification;
                var password = _getProperties.password;

                this.set('login_error_message', "");
                this.set('password', "");
                this.set('in_progress', true);
                this.get('session').authenticate('authenticator:oauth2', identification, password).then(function () {
                    self.set('in_progress', false);
                    _ember['default'].$('#login-modal').modal('hide');
                    self.get('session').unloadSensitive();
                    self.send('reload');
                }, function (error) {
                    self.set('in_progress', false);
                    if ("error" in error) {
                        if (error.error === "unauthorized_client") {
                            self.set('login_error_message', "Neexistující uživatel nebo špatné heslo");
                        } else if (error.error === "account_disabled") {
                            self.set('login_error_message', "Tento účet je deaktivován, kontaktuj orgy");
                        } else {
                            console.log(error.error);
                            self.set('login_error_message', "Interní chyba přihlášení: " + error.error);
                        }
                    } else {
                        self.set('login_error_message', "Interní chyba serveru: " + error.error);
                    }
                });
            },

            feedback: function feedback() {
                var self = this;
                this.set("feedback_error", "");
                this.set("feedback_sending", true);

                var obj = {
                    body: this.get("feedback_text"),
                    email: this.get("feedback_email")
                };

                _ember['default'].$.ajax({
                    url: _ksiConfigEnvironment['default'].API_LOC + "/feedback",
                    data: JSON.stringify(obj),
                    contentType: "application/json",
                    type: 'POST',
                    success: function success() {
                        self.set("feedback_email", "");
                        self.set("feedback_text", "");
                        self.set("feedback_sending", false);
                        self.set("feedback_sent", true);
                    },
                    error: function error(resp) {
                        self.set("feedback_sending", false);
                        var e = "Už ani nahlásit chybu nejde, za to beztak může Los Karlos! Napiš nám na ksi@fi.muni.cz.<br>" + resp.message;
                        if (resp.errors && resp.errors[0]) {
                            e += "<br>" + resp.errors[0].status + " : " + resp.errors[0].title;
                        }
                        self.set("feedback_error", e);
                    }
                });
            },

            close_feedback: function close_feedback() {
                var self = this;
                _ember['default'].$('#feedback-modal').modal('hide');
                _ember['default'].run.later(self, function () {
                    self.set("feedback_sent", false);
                }, 1000);
            }

        },

        currentRouteDidChange: (function () {
            _emberSimpleAuthConfiguration['default'].routeAfterAuthentication = this.currentRouteName;
        }).observes('currentRouteName'),

        feedback_email: _ember['default'].computed("session.current_user", function () {
            return this.get("session.current_user.email");
        }),

        init: function init() {
            // Automaticcaly focus object in class "autofocus" when modal dialog is shown.
            _ember['default'].$(document).on('shown.bs.modal', function () {
                _ember['default'].$(this).find('.autofocus').focus();
            });
        }

    });
});