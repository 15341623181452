define("ksi/routes/admin/year-edit", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model(params) {
            return _ember["default"].RSVP.hash({
                year: this.store.find("year", params["year_id"]),
                orgs: this.store.query("user", { filter: "orgs-all" })
            });
        },
        title: "KSI: Úprava ročníku",
        actions: {
            willTransition: function willTransition() {
                this.controller.set('save_status', "");
                this.controller.set('error_status', "");
                this.conroller.set('orgs', undefined);
            }
        }
    });
});