define("ksi/templates/settings/password", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 23,
              "column": 4
            }
          },
          "moduleName": "ksi/templates/settings/password.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Staré heslo:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Nové heslo (alespoň 6 znaků):");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Ověření hesla:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "submit");
          dom.setAttribute(el1, "style", "margin-top:20px; margin-bottom:20px; width:100%;");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [15]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 13, 13, contextualElement);
          morphs[4] = dom.createAttrMorph(element0, 'class');
          morphs[5] = dom.createMorphAt(element0, 1, 1);
          morphs[6] = dom.createMorphAt(fragment, 17, 17, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "old_password", ["loc", [null, [8, 22], [8, 34]]]]], [], []], "type", "password", "name", "pass", "placeholder", "staré heslo", "required", true, "class", "form-control"], ["loc", [null, [8, 8], [8, 125]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "new_password", ["loc", [null, [10, 22], [10, 34]]]]], [], []], "type", "password", "name", "pass", "placeholder", "nové heslo", "required", true, "pattern", ".{6,}", "class", "form-control"], ["loc", [null, [10, 8], [10, 140]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "new_password2", ["loc", [null, [12, 22], [12, 35]]]]], [], []], "type", "password", "name", "pass", "placeholder", "nové heslo znovu", "required", true, "pattern", ".{6,}", "class", "form-control"], ["loc", [null, [12, 8], [12, 147]]]], ["inline", "ale-rt", [], ["closeBtn", false, "type", "danger", "text", ["subexpr", "@mut", [["get", "submit_error", ["loc", [null, [14, 51], [14, 63]]]]], [], []]], ["loc", [null, [14, 8], [14, 65]]]], ["attribute", "class", ["concat", ["btn btn-primary ", ["subexpr", "if", [["get", "saving", ["loc", [null, [16, 58], [16, 64]]]], "disabled"], [], ["loc", [null, [16, 53], [16, 77]]]]]]], ["inline", "if", [["get", "saving", ["loc", [null, [18, 17], [18, 23]]]], "Měním heslo...", "Změnit heslo!"], [], ["loc", [null, [18, 12], [18, 58]]]], ["inline", "ale-rt", [], ["type", ["subexpr", "@mut", [["get", "alertType", ["loc", [null, [21, 22], [21, 31]]]]], [], []], "closeBtn", true, "text", ["subexpr", "@mut", [["get", "alertMsg", ["loc", [null, [21, 51], [21, 59]]]]], [], []]], ["loc", [null, [21, 8], [21, 61]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/settings/password.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "margin-top:30px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("Změna hesla");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "password-email-container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "validatable-form", [], ["action", "change_password"], 0, null, ["loc", [null, [6, 4], [23, 25]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});