define("ksi/routes/admin/article-create", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        title: "KSI: Nový článek",
        actions: {
            willTransition: function willTransition() {
                this.controller.set('title', "");
                this.controller.set('body', "");
                this.controller.set('published', false);
                this.controller.set('picture', "");
            }
        }
    });
});