define("ksi/templates/nabor", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/nabor.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "container content-section autojustify");
        dom.setAttribute(el1, "style", "margin-bottom:40px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "id", "nabor");
        var el3 = dom.createTextNode("Staň se organizátorem KSI!");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n  Máš zájem pomoct středoškolákům poznat informatiku? Baví tě vymýšlet úlohy?\n  Chceš se přidat do různorodého kolektivu aktivních organizátorů, od prváků až po doktorandy?\n  \n  Pokud je tvá odpověď ano, rozhodně se neváhej nám napsat. Rádi od tebe uslyšíme a setkáme se s tebou.\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "mailto:ksi@fi.muni.cz");
        var el4 = dom.createTextNode("ksi@fi.muni.cz");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("\n  PS: Ano, tahle stránka je work in progress. Pokud by tě vymýšlení úloh moc nebralo,\n  zato by sis ale rád šáhnul na webové technologie a něco reálně užívaného vytvořil,\n  i na tom by se dalo domluvit. Stačí napsat se nám ozvat mailem, nebo se osobně poptat někoho z organizátorů.\n  Jejich seznam je dole na hlavní stránce KSI a je dobrá šance, že se z někým znich znáš nebo tě třeba cvičí. :)\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});