define("ksi/models/wave", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        'caption': _emberData["default"].attr("string"),
        'garant': _emberData["default"].belongsTo("user", { async: true }),
        'public': _emberData["default"].attr("boolean"),
        'time_published': _emberData["default"].attr("date"),
        'year': _emberData["default"].belongsTo("year", { async: true }),
        'index': _emberData["default"].attr("number"),
        'sum_points': _emberData["default"].attr("number"),
        'tasks_cnt': _emberData["default"].attr("number")
    });
});