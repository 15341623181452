define("ksi/controllers/orgs", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),

        orgs_current: _ember["default"].computed("model", "session.year", function () {
            var year = this.get("session.year");
            return this.get("model").filter(function (item) {
                return item.get("seasons").map(function (i) {
                    return Number(i.get("id"));
                }).contains(year);
            });
        }),

        orgs_other: _ember["default"].computed("model", "session.year", function () {
            var year = this.get("session.year");
            return this.get("model").filter(function (item) {
                return !item.get("seasons").map(function (i) {
                    return Number(i.get("id"));
                }).contains(year);
            });
        })

    });
});