define("ksi/templates/footer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/footer.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" footer content ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("footer");
        dom.setAttribute(el1, "class", "text-center");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "footer-above hidden-print");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "footer-col col-md-12");
        var el6 = dom.createTextNode("\n                      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "style", "text-align: center; color: #fff; margin-top:-10px");
        var el7 = dom.createElement("h3");
        var el8 = dom.createTextNode("Odkazy");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "row row-centered");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "mailto:ksi@fi.muni.cz");
        dom.setAttribute(el7, "title", "ksi@fi.muni.cz");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "mail-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://www.facebook.com/ksi.fi.muni");
        dom.setAttribute(el7, "title", "Facebook");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "facebook-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://www.fi.muni.cz/");
        dom.setAttribute(el7, "title", "Fakulta Informatiky MU");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "fi-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://www.muni.cz/");
        dom.setAttribute(el7, "title", "Masarykova Univerzita Brno");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "mu-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://kscuk.fi.muni.cz/");
        dom.setAttribute(el7, "title", "K-SCUK");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "kscuk-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://intersob.math.muni.cz");
        dom.setAttribute(el7, "title", "Soutěž InterSoB");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "sob-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://interlos.fi.muni.cz");
        dom.setAttribute(el7, "title", "INTERnetová LOgická Soutěž");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "los-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://zverinec.fi.muni.cz");
        dom.setAttribute(el7, "title", "Spolek přátel severské zvěře");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "zverinec-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "http://brkos.math.muni.cz");
        dom.setAttribute(el7, "title", "BRněnský KOrespondenční Seminář");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "brkos-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "http://ibis.sci.muni.cz/");
        dom.setAttribute(el7, "title", "Interaktivní BIologický Seminář");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "ibis-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://ksp.mff.cuni.cz");
        dom.setAttribute(el7, "title", "Korespondenční seminář z programování");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "ksp-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://www.youtube.com/channel/UCH0K2_tAhkJNlvfRo5NsEIg");
        dom.setAttribute(el7, "title", "YouTube");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "youtube-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://github.com/fi-ksi");
        dom.setAttribute(el7, "title", "KSI @ GitHub");
        dom.setAttribute(el7, "class", "footer-logo inline-footer");
        dom.setAttribute(el7, "id", "github-logo");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "style", "opacity: 0.001; height: 1px;");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/email-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/facebook-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/youtube-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/fi-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/mu-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/kscuk-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/zverinec-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/ibis-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/brkos-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/intersob-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/los-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/ksp-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/img/logos/github-hover.svg");
        dom.setAttribute(el7, "width", "1");
        dom.setAttribute(el7, "height", "1");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                      ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "footer-below");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-lg-12");
        var el6 = dom.createTextNode("\n                    Obsah tohoto webu je poskytován pod licencí\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "https://creativecommons.org/licenses/by-nc/4.0/");
        var el7 = dom.createTextNode("CC BY-NC v4.0");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(".\n                    Copyright © ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "/img/ee/tuxs.gif");
        dom.setAttribute(el6, "target", "_blank");
        var el7 = dom.createTextNode("KSI");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" 2015–2022.\n                    \n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    Tato aktivita probíhá díky podpoře Ministerstva školství, mládeže a tělovýchovy, ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    dotace na podporu nadaných žáků základních a středních škol v roce 2021 (projekt č. 0064/7/NAD/2021).\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});