define("ksi/models/task", ["exports", "ember-data", "ember", "ksi/config/environment"], function (exports, _emberData, _ember, _ksiConfigEnvironment) {
    exports["default"] = _emberData["default"].Model.extend({
        prerequisities: _emberData["default"].attr("prerequisite"),

        state: _emberData["default"].attr("string"),
        active: _ember["default"].computed("state", function () {
            return ["base", "correcting", "done"].indexOf(this.get("state")) > -1;
        }),

        title: _emberData["default"].attr("string"),
        author: _emberData["default"].belongsTo("user", { async: true, inverse: null }),
        co_author: _emberData["default"].belongsTo("user", { async: true, inverse: null }),
        intro: _emberData["default"].attr("string"),
        max_score: _emberData["default"].attr("number"),

        time_deadline: _emberData["default"].attr("date"),
        time_published: _emberData["default"].attr("date"),

        wave: _emberData["default"].belongsTo("wave", { async: true }),

        picture_base: _emberData["default"].attr("string"),
        picture_suffix: _emberData["default"].attr("string"),

        picture: _ember["default"].computed("picture_base", "picture_suffix", "active", "state", function () {
            if (!this.get("picture_base")) {
                return undefined;
            }
            if (!this.get("active")) {
                return _ksiConfigEnvironment["default"].API_LOC + this.get("picture_base") + "locked" + this.get("picture_suffix");
            }
            return _ksiConfigEnvironment["default"].API_LOC + this.get("picture_base") + this.get("state") + this.get("picture_suffix");
        }),
        details: _emberData["default"].belongsTo("task-detail", { async: true }),
        feedbacks: _emberData["default"].belongsTo("feedback", { async: true })
    });
});