define("ksi/routes/admin/achievement-grant", ["exports", "ember", "ksi/mixins/reset-scroll-protected"], function (exports, _ember, _ksiMixinsResetScrollProtected) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollProtected["default"], {
        model: function model() {
            return _ember["default"].RSVP.hash({
                tasks: this.store.findAll("task"),
                users: this.store.findAll("user"),
                achievements: this.store.findAll("achievement")
            });
        },
        title: "KSI: Udělit trofej",
        actions: {
            willTransition: function willTransition() {
                this.controller.set('sel_users', []);
                this.controller.set('achievement', undefined);
                this.controller.set('grant_status', "");
                this.controller.set('grant_error', "");
            }
        }
    });
});