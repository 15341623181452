define("ksi/templates/components/achievement-small", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/components/achievement-small.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "width", "30px;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createAttrMorph(element0, 'alt');
        morphs[2] = dom.createAttrMorph(element0, 'title');
        return morphs;
      },
      statements: [["attribute", "src", ["concat", [["get", "model.picture", ["loc", [null, [1, 12], [1, 25]]]]]]], ["attribute", "alt", ["concat", [["get", "model.shortTitle", ["loc", [null, [1, 51], [1, 67]]]]]]], ["attribute", "title", ["concat", [["get", "model.title", ["loc", [null, [1, 82], [1, 93]]]]]]]],
      locals: [],
      templates: []
    };
  })());
});