define("ksi/controllers/task/assignment", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),
        module_service: _ember["default"].inject.service('module-service'),
        resubmit: false,
        opened: _ember["default"].computed("model.time_deadline", function () {
            if (!this.get("model.time_deadline")) {
                return true;
            }
            var currentdate = new Date();
            if (this.get("model.time_deadline") > currentdate) {
                return true;
            }
            return false;
        }),
        finished: _ember["default"].computed('model.details.modules.[]', function () {
            var res = true;
            var modules = this.get('model.details.modules');
            if (_ember["default"].isEmpty(modules)) {
                return false;
            }
            modules.forEach(function (x) {
                res &= x.get("is_correct");
            });
            return res;
        }),
        module_ids: _ember["default"].computed("model", function () {
            return this.get("model.modules").map(function (module) {
                return "module_" + module.get("id");
            });
        }),
        submit_error: "",
        actions: {
            submit: function submit() {
                var self = this;
                this.get("model").reload().then(function (e) {
                    e.get("details").then(function (d) {
                        d.reload().then(function (nd) {
                            var res = true;
                            var modules = nd.get('modules');
                            if (!modules) {
                                self.set("finished", false);
                            }
                            modules.forEach(function (x) {
                                res &= x.get("is_correct");
                            });
                            self.set("finished", res);
                        });
                    });
                });
            }
        }
    });
});