define("ksi/controllers/forgot-password", ["exports", "ember", "ksi/config/environment"], function (exports, _ember, _ksiConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({
        general_error: "",
        sending: false,

        actions: {
            send: function send() {
                var self = this;
                this.set("general_error", "");
                this.set("sending", true);

                _ember["default"].$.ajax({
                    url: _ksiConfigEnvironment["default"].API_LOC + "/forgottenPassword",
                    data: JSON.stringify(self.get("model")),
                    contentType: "application/json",
                    type: 'POST',
                    success: function success() {
                        self.set("finished", true);
                        self.set("sending", false);
                    },
                    error: function error(xhr) {
                        self.set("sending", false);

                        if (xhr.status === 400) {
                            self.set("general_error", "Tento e-mail nemáme v databázi!");
                        } else {
                            self.set("general_error", "Špatná odpověď serveru, kontaktuj organizátora!");
                        }
                    }
                });
            }
        }
    });
});