define("ksi/routes/registration", ["exports", "ember", "ksi/mixins/reset-scroll-unauthenticated"], function (exports, _ember, _ksiMixinsResetScrollUnauthenticated) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScrollUnauthenticated["default"], {
        model: function model() {
            var profile = this.store.createRecord('registration');
            return profile;
        },
        title: "KSI: Registrace",
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('model', model);
            controller.set('registration_done', false);
            controller.set('registration_in_progress', false);
        }
    });
});