define("ksi/templates/components/thread-post", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 8
              },
              "end": {
                "line": 6,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "class", "media-object");
            dom.setAttribute(el1, "style", "width:50px; border-radius: 5px;");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element10, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "model.author.profile_picture_r", ["loc", [null, [5, 43], [5, 73]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 74
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "model.author.full_name", ["loc", [null, [10, 48], [10, 74]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn-xs btn-primary");
              dom.setAttribute(el1, "style", "border:none; margin-top: 10px;");
              var el2 = dom.createTextNode("Upravit");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element9);
              return morphs;
            },
            statements: [["element", "action", ["edit"], [], ["loc", [null, [13, 86], [13, 103]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 15,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "is_editing", ["loc", [null, [12, 20], [12, 30]]]]], [], 0, null, ["loc", [null, [12, 10], [14, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 19,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn-xs btn-primary");
              dom.setAttribute(el1, "style", "border:none; margin-top: 10px; background: #fbb04175;");
              var el2 = dom.createTextNode("Smazat");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [["element", "action", ["delete"], [], ["loc", [null, [18, 109], [18, 128]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 10
                },
                "end": {
                  "line": 21,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn-xs btn-primary");
              dom.setAttribute(el1, "style", "border:none; margin-top: 10px");
              var el2 = dom.createTextNode("Smazat");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element7);
              return morphs;
            },
            statements: [["element", "action", ["delete"], [], ["loc", [null, [20, 85], [20, 104]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 22,
                "column": 8
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.reaction", ["loc", [null, [17, 16], [17, 30]]]]], [], 0, 1, ["loc", [null, [17, 10], [21, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "discussion-body");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "model.body", ["loc", [null, [26, 12], [26, 28]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 10
                },
                "end": {
                  "line": 38,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-sm btn-primary disabled");
              var el2 = dom.createTextNode("Ukládám...");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-sm btn-primary disabled");
              var el2 = dom.createTextNode("Zrušit");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 10
                },
                "end": {
                  "line": 41,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-sm btn-primary");
              var el2 = dom.createTextNode("Uložit");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-sm btn-primary");
              var el2 = dom.createTextNode("Zrušit");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createElementMorph(element5);
              return morphs;
            },
            statements: [["element", "action", ["save"], [], ["loc", [null, [39, 53], [39, 70]]]], ["element", "action", ["cancel"], [], ["loc", [null, [40, 53], [40, 72]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 46,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "panel panel-default");
            dom.setAttribute(el1, "style", "border:none; box-shadow: none;");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "controls form-inline");
            dom.setAttribute(el2, "style", "margin-top:20px");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "margin-top:20px; margin-bottom:20px");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(element6, 5, 5);
            return morphs;
          },
          statements: [["inline", "text-editor", [], ["value", ["subexpr", "@mut", [["get", "model.body", ["loc", [null, [31, 34], [31, 44]]]]], [], []]], ["loc", [null, [31, 14], [31, 46]]]], ["block", "if", [["get", "edit_progress", ["loc", [null, [35, 16], [35, 29]]]]], [], 0, 1, ["loc", [null, [35, 10], [41, 17]]]], ["inline", "ale-rt", [], ["type", "danger", "closeBtn", true, "text", ["subexpr", "@mut", [["get", "edit_error", ["loc", [null, [44, 52], [44, 62]]]]], [], []]], ["loc", [null, [44, 10], [44, 64]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 14
                  },
                  "end": {
                    "line": 51,
                    "column": 150
                  }
                },
                "moduleName": "ksi/templates/components/thread-post.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 10
                },
                "end": {
                  "line": 53,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn-xs btn-primary");
              dom.setAttribute(el1, "style", "border:none; margin-top: 10px");
              var el2 = dom.createTextNode("→ Řešitelovo odevzdání\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3, 1, 1);
              return morphs;
            },
            statements: [["element", "action", ["openUsersSubmission"], [], ["loc", [null, [50, 85], [50, 117]]]], ["block", "link-to", ["admin/correcting", ["subexpr", "query-params", [], ["task_", ["get", "taskId", ["loc", [null, [51, 64], [51, 70]]]], "participant_", ["get", "model.author.id", ["loc", [null, [51, 84], [51, 99]]]]], ["loc", [null, [51, 44], [51, 100]]]]], ["style", "visibility:none;", "class", "toSubmissions"], 0, null, ["loc", [null, [51, 14], [51, 162]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 6
              },
              "end": {
                "line": 54,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "taskId", ["loc", [null, [49, 16], [49, 22]]]]], [], 0, null, ["loc", [null, [49, 10], [53, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 12
                },
                "end": {
                  "line": 57,
                  "column": 46
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" Zrušit reakci ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 46
                },
                "end": {
                  "line": 57,
                  "column": 64
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" Reagovat ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 6
              },
              "end": {
                "line": 60,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn-xs btn-primary");
            dom.setAttribute(el1, "style", "border:none; margin-top: 10px");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["react"], [], ["loc", [null, [56, 83], [56, 101]]]], ["block", "if", [["get", "is_reacting", ["loc", [null, [57, 18], [57, 29]]]]], [], 0, 1, ["loc", [null, [57, 12], [57, 71]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child8 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 8
                },
                "end": {
                  "line": 67,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ale-rt", [], ["type", "danger", "text", "Pro přispívání do diskuse musíš být přihlášen."], ["loc", [null, [66, 10], [66, 88]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 8
                },
                "end": {
                  "line": 78,
                  "column": 8
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "panel panel-default");
              dom.setAttribute(el1, "style", "border:none; box-shadow: none; background-color: inherit;");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "controls form-inline");
              dom.setAttribute(el2, "style", "margin-top:20px");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              dom.setAttribute(el3, "for", "threadName");
              var el4 = dom.createTextNode("Příspěvek:");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("button");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [5]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 3, 3);
              morphs[1] = dom.createMorphAt(element0, 3, 3);
              morphs[2] = dom.createAttrMorph(element1, 'class');
              morphs[3] = dom.createElementMorph(element1);
              morphs[4] = dom.createMorphAt(element1, 0, 0);
              morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "text-editor", [], ["value", ["subexpr", "@mut", [["get", "response_text", ["loc", [null, [71, 38], [71, 51]]]]], [], []]], ["loc", [null, [71, 18], [71, 53]]]], ["inline", "ale-rt", [], ["type", "warning", "style", "margin-top:10px; font-size:87.5% !important;", "text", "Pokud chceš do příspěvku vložit obrázek, vlož jej odkazem. Načítání obrázků z&nbsp;externích zdrojů je na KSI webu zakázáno pomocí CSP."], ["loc", [null, [73, 14], [73, 235]]]], ["attribute", "class", ["concat", ["btn btn-sm btn-primary ", ["subexpr", "if", [["get", "react_progress", ["loc", [null, [74, 57], [74, 71]]]], "disabled"], [], ["loc", [null, [74, 52], [74, 84]]]]]]], ["element", "action", ["send"], [], ["loc", [null, [75, 16], [75, 33]]]], ["inline", "if", [["get", "react_progress", ["loc", [null, [75, 39], [75, 53]]]], "Odesílám...", "Odeslat"], [], ["loc", [null, [75, 34], [75, 79]]]], ["inline", "ale-rt", [], ["type", "danger", "closeBtn", true, "show", ["subexpr", "@mut", [["get", "react_error_show", ["loc", [null, [77, 52], [77, 68]]]]], [], []], "text", ["subexpr", "@mut", [["get", "react_error", ["loc", [null, [77, 74], [77, 85]]]]], [], []]], ["loc", [null, [77, 10], [77, 87]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 6
              },
              "end": {
                "line": 79,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "session.isAuthenticated", ["loc", [null, [65, 18], [65, 41]]]]], [], 0, 1, ["loc", [null, [65, 8], [78, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child9 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 83,
                  "column": 10
                },
                "end": {
                  "line": 85,
                  "column": 10
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "thread-post", [], ["model", ["subexpr", "@mut", [["get", "react", ["loc", [null, [84, 34], [84, 39]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [84, 46], [84, 51]]]]], [], []], "depth", ["subexpr", "@mut", [["get", "depth_inc", ["loc", [null, [84, 58], [84, 67]]]]], [], []]], ["loc", [null, [84, 14], [84, 69]]]]],
            locals: ["react"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 6
              },
              "end": {
                "line": 86,
                "column": 6
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.reaction", ["loc", [null, [83, 18], [83, 32]]]]], [], 0, null, ["loc", [null, [83, 10], [85, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child10 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 92,
                  "column": 6
                },
                "end": {
                  "line": 94,
                  "column": 6
                }
              },
              "moduleName": "ksi/templates/components/thread-post.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "thread-post", [], ["model", ["subexpr", "@mut", [["get", "react", ["loc", [null, [93, 30], [93, 35]]]]], [], []], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [93, 42], [93, 47]]]]], [], []], "depth", ["subexpr", "@mut", [["get", "depth_inc", ["loc", [null, [93, 54], [93, 63]]]]], [], []]], ["loc", [null, [93, 10], [93, 65]]]]],
            locals: ["react"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.3",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 2
              },
              "end": {
                "line": 95,
                "column": 2
              }
            },
            "moduleName": "ksi/templates/components/thread-post.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.reaction", ["loc", [null, [92, 14], [92, 28]]]]], [], 0, null, ["loc", [null, [92, 6], [94, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 98,
              "column": 0
            }
          },
          "moduleName": "ksi/templates/components/thread-post.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" pociatok prispevku ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "media-left");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" foto autora prispevku ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "media-body");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "discussion-caption");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(", ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(", ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" meno, status, cas");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" button odkryje textareau: ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" vnorena reakcia vnorene ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" vnorena reakcia nevnorene ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [0]);
          var element12 = dom.childAt(element11, [5]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(element12, [7]);
          var morphs = new Array(14);
          morphs[0] = dom.createAttrMorph(element11, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(element13, 1, 1);
          morphs[3] = dom.createMorphAt(element13, 3, 3);
          morphs[4] = dom.createMorphAt(element13, 5, 5);
          morphs[5] = dom.createMorphAt(element13, 6, 6);
          morphs[6] = dom.createMorphAt(element13, 8, 8);
          morphs[7] = dom.createMorphAt(element13, 9, 9);
          morphs[8] = dom.createMorphAt(element12, 5, 5);
          morphs[9] = dom.createMorphAt(element14, 1, 1);
          morphs[10] = dom.createMorphAt(element14, 2, 2);
          morphs[11] = dom.createMorphAt(element12, 11, 11);
          morphs[12] = dom.createMorphAt(element12, 15, 15);
          morphs[13] = dom.createMorphAt(element11, 9, 9);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["media media-thread ", ["subexpr", "if", [["get", "model.is_new", ["loc", [null, [2, 35], [2, 47]]]], "new-post"], [], ["loc", [null, [2, 30], [2, 60]]]]]]], ["block", "link-to", ["user-profile", ["get", "model.author", ["loc", [null, [4, 34], [4, 46]]]]], [], 0, null, ["loc", [null, [4, 8], [6, 20]]]], ["block", "link-to", ["user-profile", ["get", "model.author", ["loc", [null, [10, 34], [10, 46]]]]], [], 1, null, ["loc", [null, [10, 8], [10, 86]]]], ["content", "model.author.role_str", ["loc", [null, [10, 88], [10, 113]]]], ["inline", "date-and-time", [["get", "model.published_at", ["loc", [null, [10, 131], [10, 149]]]], "cs"], [], ["loc", [null, [10, 115], [10, 156]]]], ["inline", "if", [["get", "model.is_new", ["loc", [null, [10, 161], [10, 173]]]], ", nové"], [], ["loc", [null, [10, 156], [10, 184]]]], ["block", "if", [["get", "allow_edit", ["loc", [null, [11, 14], [11, 24]]]]], [], 2, null, ["loc", [null, [11, 8], [15, 15]]]], ["block", "if", [["get", "allow_delete", ["loc", [null, [16, 14], [16, 26]]]]], [], 3, null, ["loc", [null, [16, 8], [22, 15]]]], ["block", "unless", [["get", "is_editing", ["loc", [null, [24, 16], [24, 26]]]]], [], 4, 5, ["loc", [null, [24, 6], [46, 17]]]], ["block", "if", [["get", "isOrg", ["loc", [null, [48, 12], [48, 17]]]]], [], 6, null, ["loc", [null, [48, 6], [54, 13]]]], ["block", "unless", [["get", "model.thread.year.sealed", ["loc", [null, [55, 16], [55, 40]]]]], [], 7, null, ["loc", [null, [55, 6], [60, 17]]]], ["block", "if", [["get", "is_reacting", ["loc", [null, [64, 12], [64, 23]]]]], [], 8, null, ["loc", [null, [64, 6], [79, 13]]]], ["block", "unless", [["get", "depth_exceeded", ["loc", [null, [82, 16], [82, 30]]]]], [], 9, null, ["loc", [null, [82, 6], [86, 17]]]], ["block", "if", [["get", "depth_exceeded", ["loc", [null, [91, 8], [91, 22]]]]], [], 10, null, ["loc", [null, [91, 2], [95, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 99,
            "column": 0
          }
        },
        "moduleName": "ksi/templates/components/thread-post.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "model.temporary", ["loc", [null, [1, 10], [1, 25]]]]], [], 0, null, ["loc", [null, [1, 0], [98, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});