define("ksi/controllers/admin/years", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        store: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),

        actions: {
            'year-delete': function yearDelete(year) {
                if (!confirm("Opravdu odstranit ročník " + year.get("year") + "?")) {
                    return;
                }

                year.set("deleting", true);
                year.destroyRecord().then({}, function (error) {
                    year.set("deleting", false);
                    alert("Ročník se nepodařilo odstranit, kontaktuj administrátora:\n" + error);
                });
            }
        }

    });
});