define("ksi/controllers/admin/task-edit", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),
        save_status: "",
        error_status: "",
        saving: false,

        actions: {
            'task-save': function taskSave() {
                var self = this;

                this.set("save_status", "");
                this.set("error_status", "");
                this.set("saving", true);

                this.get("model").save().then(function () {
                    self.set("saving", false);
                    self.set("save_status", "Úloha uložena.");
                }, function () {
                    self.set("saving", false);
                    self.set("error_status", "Chybová odpověď serveru! Kontaktuj administrátora.");
                });
            }
        },

        canSave: _ember["default"].computed("model", "model.wave", "session.current_user", "model.wave.garant", "model.author", function () {
            var user = this.get("session.current_user");
            if (user) {
                return user.get("admin") || user.get("id") === this.get("model.wave.garant.id") && new Date() < this.get("model.wave.time_published");
            } else {
                return undefined;
            }
        })

    });
});