define("ksi/controllers/admin/article-edit", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        session: _ember["default"].inject.service(),
        save_status: "",
        error_status: "",
        saving: false,

        actions: {
            'article-save': function articleSave() {
                var self = this;
                this.set("saving", true);
                this.set("save_status", "");
                this.set("error_status", "");
                this.get("model").save().then(function () {
                    self.set("saving", false);
                    self.set("save_status", "Článek uložen.");
                }, function () {
                    self.set("saving", false);
                    self.set("error_status", "Špatná odpověď ze serveru! Zkus to za chvíli znovu. Pokud problém přetrvává, kontaktuj organizátora.");
                });
            }
        }

    });
});