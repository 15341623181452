define("ksi/routes/results", ["exports", "ember", "ksi/mixins/reset-scroll"], function (exports, _ember, _ksiMixinsResetScroll) {
    exports["default"] = _ember["default"].Route.extend(_ksiMixinsResetScroll["default"], {
        model: function model() {
            this.store.unloadAll("user");
            return _ember["default"].RSVP.hash({
                part_hs: this.store.query("user", { filter: "part-hs", sort: "score" }),
                part_other: this.store.query("user", { filter: "part-other", sort: "score" })
            });
        },
        title: "KSI: Výsledky"
    });
});